import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import { TOKEN } from '../../common/api';
import axios, { HttpStatusCode } from 'axios';
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: ``,
          },
        ]}
      >
        <Input className='py-0 text-[10px] text-center font-bold' ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};


export const WalletInsertTable = ({ type, openNotificationWithIcon, reloadData }) => {
  const [dataSource, setDataSource] = useState([
  ]);
  const [count, setCount] = useState(2);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const defaultColumns = type == "account" ?
    [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
        editable: true,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>계좌번호</div>,
        dataIndex: 'Memo',
        width: 100,
        editable: true,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>전주지도</div>,
        dataIndex: '1M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 경도</div>,
        dataIndex: '1M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 수원부</div>,
        dataIndex: '1M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>철옹성전도</div>,
        dataIndex: '1M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 기장현</div>,
        dataIndex: '2M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제부</div>,
        dataIndex: '2M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 영일현</div>,
        dataIndex: '2M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>해동지도 중 울릉도</div>,
        dataIndex: '2M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>원산지도</div>,
        dataIndex: '2M5',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>광주지도</div>,
        dataIndex: '3M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>순천부지도</div>,
        dataIndex: '3M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>장흥부지도</div>,
        dataIndex: '3M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
        dataIndex: 'SUM',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: '',
        dataIndex: 'operation',
        width: 60,
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <Popconfirm title="삭제하시겠습니까?" okButtonProps={{ className: "bg-[blue]" }} onConfirm={() => handleDelete(record.key)}>
              <a>삭제</a>
            </Popconfirm>
          ) : null,
      },
    ] : [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
        editable: true,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 연변부</div>,
        dataIndex: '1S1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>동국여도 중 북한성도</div>,
        dataIndex: '1S2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 전주지도</div>,
        dataIndex: '1S3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제영등진지도</div>,
        dataIndex: '2S1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>울릉도외도</div>,
        dataIndex: '2S2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>울릉도도형</div>,
        dataIndex: '2S3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>호남지도 중 해남</div>,
        dataIndex: '3S1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>호남지도 중 장성부</div>,
        dataIndex: '3S2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>송광사사적</div>,
        dataIndex: '3S3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>비고</div>,
        dataIndex: 'Memo',
        width: 100,
        editable: true,
      },
      // {
      //   title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
      //   dataIndex: 'SUM',
      //   editable: true,
      //   render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      // },
      {
        title: '',
        dataIndex: 'operation',
        width: 60,
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <Popconfirm title="삭제하시겠습니까?" okButtonProps={{ className: "bg-[blue]" }} onConfirm={() => handleDelete(record.key)}>
              <a>삭제</a>
            </Popconfirm>
          ) : null,
      },
    ]

  useEffect(() => {
    setDataSource([])
  }, [type])

  const requestSave = async () => {
    const res = await axios.put(`/api/admins/${type}`, {
      info: JSON.stringify(dataSource),
    }, {
      headers: {
        "x-access-token": TOKEN
      }
    })
    if (res.data.code == HttpStatusCode.Ok) {
      openNotificationWithIcon('success', "조작이 성공하였습니다.")
      setDataSource([])
      reloadData()
    }
  };

  const handleAdd = () => {
    const newData = {
      key: count,
      "1M1": 0,
      "1S1": 0,
    }
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className='flex flex-col'>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        pagination={{ position: ["none"] }}
        dataSource={dataSource}
        columns={columns}
      />

      <div className='flex flex-row items-center self-center mt-[30px] gap-x-[20px]'>
        <Button
          onClick={handleAdd}
          className='self-center bg-[#D5D5D5] rounded-[20px]'
          style={{
            marginBottom: 16,
          }}
        >
          추가하기
        </Button>
        <Button
          onClick={requestSave}
          className='self-center bg-[#D0CCFF] rounded-[20px]'
          style={{
            marginBottom: 16,
          }}
        >
          저장
        </Button>
      </div>
    </div>
  );
};
