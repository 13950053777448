import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Layout from './Layout';
import Holder from '../pages/Holder';
import Admin from '../pages/Admin';
import Minting from '../pages/Minting';
import Notice from '../pages/Notice';
import NoticeDetail from '../pages/NoticeDetail';
const PageRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="notice" element={<Notice />} />
                    <Route path="notice-detail" element={<NoticeDetail />} />
                    <Route path="holder" element={<Holder />} />
                    <Route path="minting" element={<Minting />} />
                    <Route path="admin" element={<Admin />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
export default PageRoutes;