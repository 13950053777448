import { Button, Input, Modal, Table, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios, { HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { TOKEN } from "../common/api";
import BtnCategory2 from "../components/btn/BtnCategory2";
import { WalletTable } from "../components/table/WalletTable";

const WALLET_PAGE_SIZE = 15;
const NOTICE_PAGE_SIZE = 5;

const Context = React.createContext({ name: "Default" });

const Admin = () => {
  const [showContents, setShowContents] = useState(false);
  const [noti, contextHolder] = notification.useNotification();

  const [categories, setCategories] = useState([
    { name: "지갑별 민팅수량 조회", selected: true, value: "minting" },
    { name: "계좌이체 후 에어드랍 조회", selected: false, value: "account" },
    { name: "지갑별 에어드랍된 NFT", selected: false, value: "airdrop" },
  ]);

  const walletTableColums = [
    {
      key: "WalletAddress",
      title: (filters, sortOrder) => (
        <div className="bg-[#A6FFE4]">지갑주소</div>
      ),
      dataIndex: "WalletAddress",
      width: 100,
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#FFA6A6]">전주지도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#FFA6A6]">해동지도 중 경도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#FFA6A6]">지방지도 중 수원부</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#FFA6A6]">철옹성전도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#DDFFA6]">지방지도 중 기장현</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#DDFFA6]">지방지도 중 거제부</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#DDFFA6]">지방지도 중 영일현</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#DDFFA6]">해동지도 중 울릉도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#DDFFA6]">원산지도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#A6C9FF]">광주지도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#A6C9FF]">순천부지도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => (
        <div className="bg-[#A6C9FF]">장흥부지도</div>
      ),
      dataIndex: "1M1",
    },
    {
      key: "1M1",
      title: (filters, sortOrder) => <div className="bg-[#C7C7C7]">계</div>,
      dataIndex: "1M1",
    },
  ];
  const [walletList, setWalletList] = useState([]);

  const [intro, setIntro] = useState("");
  const [roadmap, setRoadmap] = useState("");

  const [noticeList, setNoticeList] = useState([]);
  const [noticeTotalCnt, setNoticeTotalCnt] = useState(50);
  const [noticeId, setNoticeId] = useState("");
  const [noticeTitle, setNoticeTitle] = useState("");
  const [noticeContents, setNoticeContents] = useState("");
  const noticeTableColums = [
    {
      key: "Title",
      title: "",
      dataIndex: "Title",
    },
    {
      key: "CreateAt",
      title: "",
      width: 150,
      dataIndex: "CreateAt",
    },
    {
      key: "Id",
      title: "",
      dataIndex: "Id",
      width: 50,
      render: (value) => (
        <Button
          className="rounded-[15px] py-0 text-white bg-[#A6DF8B]"
          onClick={() => {
            requestGetNotificationDetail(value);
          }}
        >
          수정
        </Button>
      ),
    },
    {
      key: "Id",
      title: "",
      dataIndex: "Id",
      width: 50,
      render: (value) => (
        <Button
          className="rounded-[15px] py-0 text-white bg-[#FFA6A6]"
          onClick={() => {
            Modal.confirm({
              title: "삭제알림",
              content: "삭제된 내용은 복구되지 않습니다.",
              cancelText: "취소",
              okText: "삭제하기",
              okButtonProps: { className: "bg-[red]" },
              onOk: () => {
                requestDeleteNotification(value);
              },
            });
          }}
        >
          삭제
        </Button>
      ),
    },
  ];

  const openNotificationWithIcon = (type, title, desc) => {
    noti[type]({
      message: title,
      description: desc,
    });
  };

  useEffect(() => {
    loadNoticeList(1, NOTICE_PAGE_SIZE);
    requestCmsInfo();
    window.scrollTo(0, 0);
  }, []);

  const loadNoticeList = (p_page, p_pageSize) => {
    setNoticeList([]);
    axios.get("/api/landings/notices/count").then((response) => {
      if (response.data.code == HttpStatusCode.Ok) {
        setNoticeTotalCnt(response.data.data);
        axios
          .get(`/api/landings/notices?page=${p_page}&limit=${p_pageSize}`)
          .then((response) => {
            if (response.data.code == HttpStatusCode.Ok) {
              setNoticeList(response.data.data);
            }
          });
      }
    });
  };

  const requestCmsInfo = () => {
    axios.get("/api/landings/cms").then((response) => {
      if (response.data.code == HttpStatusCode.Ok) {
        setIntro(response.data.data.intro);
        setRoadmap(response.data.data.roadmap);
      }
    });
  };

  const requestChangeIntro = () => {
    if (!intro) {
      return;
    }
    axios
      .post(
        "/api/admins/intro",
        {
          contents: intro,
        },
        {
          headers: {
            "x-access-token": TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.code == HttpStatusCode.Ok) {
          openNotificationWithIcon("success", "조작이 성공하였습니다.");
        }
      });
  };

  const requestChangeRoadmap = () => {
    if (!roadmap) {
      return;
    }
    axios
      .post(
        "/api/admins/roadmap",
        {
          contents: roadmap,
        },
        {
          headers: {
            "x-access-token": TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.code == HttpStatusCode.Ok) {
          openNotificationWithIcon("success", "조작이 성공하였습니다.");
        }
      });
  };

  const requestPutNotification = () => {
    axios
      .put(
        "/api/admins/notice",
        {
          title: noticeTitle,
          contents: noticeContents,
        },
        {
          headers: {
            "x-access-token": TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.code == HttpStatusCode.Ok) {
          setNoticeId(null);
          setNoticeTitle(null);
          setNoticeContents(null);
          openNotificationWithIcon("success", "조작이 성공하였습니다.");
        }
      });
  };

  const requestPostNotification = (p_id) => {
    axios
      .post(
        `/api/admins/notice/${p_id}`,
        {
          title: noticeTitle,
          contents: noticeContents,
        },
        {
          headers: {
            "x-access-token": TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.code == HttpStatusCode.Ok) {
          openNotificationWithIcon("success", "조작이 성공하였습니다.");
          setNoticeId(null);
          setNoticeTitle(null);
          setNoticeContents(null);
          loadNoticeList(1, NOTICE_PAGE_SIZE);
        }
      });
  };

  const requestGetNotificationDetail = (p_id) => {
    axios.get(`/api/landings/notice/${p_id}`).then((response) => {
      if (response.data.code == HttpStatusCode.Ok) {
        setNoticeId(response.data.data.Id);
        setNoticeTitle(response.data.data.Title);
        setNoticeContents(response.data.data.Contents);
        openNotificationWithIcon("info", "아래에 현시된 정보를 수정해주세요.");
      }
    });
  };

  const requestDeleteNotification = (p_id) => {
    axios
      .delete(`/api/admins/notice/${p_id}`, {
        headers: {
          "x-access-token": TOKEN,
        },
      })
      .then((response) => {
        if (response.data.code == HttpStatusCode.Ok) {
          openNotificationWithIcon("success", "조작이 성공하였습니다.");
          setNoticeId(null);
          setNoticeTitle(null);
          setNoticeContents(null);
          loadNoticeList(1, NOTICE_PAGE_SIZE);
        }
      });
  };

  return (
    <Context.Provider>
      {contextHolder}
      {showContents ? (
        <div className="overflow-hidden bg-white py-[75px] flex flex-col items-center px-[20px]">
          <div className="flex flex-col w-full gap-y-[97px]">
            <section className="flex flex-col">
              <p className="text-[32px] font-[900] text-[#494949]">Admin</p>
              <div className="flex flex-row items-center mt-[43px] justify-between">
                <div className="flex flex-row gap-x-[15px]">
                  {categories.map((it, index) => {
                    return (
                      <BtnCategory2
                        title={it.name}
                        selected={it.selected}
                        onClick={() => {
                          const tmp = JSON.parse(JSON.stringify(categories));
                          tmp.map((it) => {
                            it.selected = false;
                          });
                          tmp[index].selected = true;
                          setCategories(tmp);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="px-[24px] md:px-0 mt-[28px] md:mt-[60px]">
                <WalletTable
                  type={categories.find((it) => it.selected).value}
                  openNotificationWithIcon={openNotificationWithIcon}
                />
              </div>
            </section>

            <section>
              <p className="text-[24px] font-[900] text-[#494949]">소개 수정</p>
              <div className="w-[509px] mt-[43px] flex flex-col items-end">
                <TextArea
                  value={intro}
                  rows={5}
                  placeholder="소개내용"
                  onChange={(e) => {
                    setIntro(e.target.value);
                  }}
                />
                <Button
                  className="mt-[11px] h-[36px] rounded-[18px] disabled:bg-[#E1E1E1] disabled:text-white"
                  disabled={!intro}
                  onClick={() => {
                    requestChangeIntro();
                  }}
                >
                  수정
                </Button>
              </div>
            </section>

            <section>
              <p className="text-[24px] font-[900] text-[#494949]">
                로드맵 수정
              </p>
              <div className="w-full mt-[43px] flex flex-col items-end">
                <TextArea
                  value={roadmap}
                  className="w-full"
                  rows={5}
                  placeholder="로드맵 내용"
                  onChange={(e) => {
                    setRoadmap(e.target.value);
                  }}
                />
                <Button
                  className="mt-[11px] h-[36px] rounded-[18px] disabled:bg-[#E1E1E1] disabled:text-white"
                  disabled={!roadmap}
                  onClick={() => {
                    requestChangeRoadmap();
                  }}
                >
                  수정
                </Button>
              </div>
            </section>

            <section>
              <p className="text-[24px] font-[900] text-[#494949]">공지사항</p>
              <div className="w-full mt-[10px] flex flex-col">
                <div className="w-[617px] admin-notice-table">
                  <Table
                    columns={noticeTableColums}
                    dataSource={noticeList}
                    pagination={{
                      total: noticeTotalCnt,
                      pageSize: NOTICE_PAGE_SIZE,
                      position: ["bottomCenter"],
                      onChange: (page, pageSize) => [
                        loadNoticeList(page, pageSize),
                      ],
                    }}
                    rowKey="Title"
                  />
                </div>

                <Input
                  className="mt-[135px]"
                  placeholder="제목을 입력하세요."
                  value={noticeTitle}
                  onChange={(e) => {
                    setNoticeTitle(e.target.value);
                  }}
                />
                <TextArea
                  className="mt-[10px]"
                  placeholder="공지사항을 입력하세요."
                  rows={5}
                  value={noticeContents}
                  onChange={(e) => {
                    setNoticeContents(e.target.value);
                  }}
                />
                <Button
                  className="mt-[11px] h-[36px] rounded-[18px] disabled:bg-[#E1E1E1] disabled:text-white self-end"
                  disabled={!noticeTitle || !noticeContents}
                  onClick={() => {
                    if (noticeId) {
                      requestPostNotification(noticeId);
                    } else {
                      requestPutNotification();
                    }
                  }}
                >
                  {noticeId ? "수정" : "등록"}
                </Button>
              </div>
            </section>
          </div>
        </div>
      ) : (
        // build password input in center
        <div className="overflow-hidden bg-white py-[75px] flex flex-col items-center px-[20px]">
          <input
            type="password"
            className="w-[300px] h-[50px] rounded-[25px] border-[1px] border-[#E1E1E1] text-[#494949] text-[16px] text-center"
            placeholder="비밀번호를 입력하세요."
            onChange={(e) => {
              if (e.target.value === "nftlab369") {
                setShowContents(true);
              }
            }}
          />
        </div>
      )}
    </Context.Provider>
  );
};
export default Admin;
