
const NFT1 = ({ nftIcon }) => {
  return (
    <img src={nftIcon} className="w-[214px] h-[372px] object-contain" />
  )
  return (
    <div className="w-[214px] h-[372px] bg-[#333333] pt-[12px] pb-[17px] rounded-[5px] flex flex-col px-[16px]">
      <div className="flex flex-row">
        <img src="https://code4chain.s3.ap-northeast-2.amazonaws.com/1_demo_site/1_nft/2_image/nft.jpg" className="w-full h-[205px] object-cover" />
      </div>
      <p className="text-[20px] text-white whitespace-pre-line mt-[17px] line-clamp-2">
        조선의 성과 궁궐{"\n"}
        전주지도
      </p>
      <div className="flex flex-row flex-1 items-end">
        <p className="text-[8px] text-white">
          ※ [참고] 구매하시는 모든 고지도 NFT는
          해당 지도의 특정 부분이 아닌,
          지도 전체의 이미지 형태로 제공됩니다.
        </p>
        <img src="https://code4chain.s3.ap-northeast-2.amazonaws.com/1_demo_site/1_nft/2_image/nft.jpg" className="w-[32px] h-[53px] object-cover ml-[16px] mb-[7px]" />
      </div>
    </div>
  )
};

export default NFT1;