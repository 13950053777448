import React, { useState, useEffect } from "react";

import { WalletButton } from "../wallet/WalletButton";
import { MintingInfo } from "../info/MintingInfo";
import styles from "./Banner.module.scss";
import axios, { HttpStatusCode } from "axios";

export const Banner = () => {
  const [nftInfoList, setNftInfoList] = useState([]);
  const [commonInfo, setCommonInfo] = useState({});
  const [itemIndex, setItemIndex] = useState(0);
  const [selectedNftInfo, setSelectedNftInfo] = useState({});

  useEffect(() => {
    loadNFTInfos();
  }, []);

  const loadNFTInfos = () => {
    axios.get("/api/mintings").then((response) => {
      if (response.data.code === HttpStatusCode.Ok) {
        setNftInfoList(response.data.data.nftInfo);
        setCommonInfo(response.data.data.commonInfo);
        setItemIndex(0);
      }
    });
  };

  const getItemTitle = () => {
    if (nftInfoList.length > itemIndex) {
      return nftInfoList[itemIndex].title;
    } else {
      return "";
    }
  };

  const getItemExp = () => {
    if (nftInfoList.length > itemIndex) {
      return nftInfoList[itemIndex].exp.replace(/\\n/g, "\n");
    } else {
      return "";
    }
  };

  const getItemImage = () => {
    if (nftInfoList.length > itemIndex) {
      return nftInfoList[itemIndex].imageUrl;
    } else {
      return "";
    }
  };

  useEffect(() => {
    const getItemInfo = () => {
      if (nftInfoList.length > itemIndex) {
        return {
          ...nftInfoList[itemIndex],
          ...commonInfo,
        };
      } else {
        return null;
      }
    };
    setSelectedNftInfo(getItemInfo());
  }, [itemIndex, nftInfoList, commonInfo]);

  return (
    <div className={styles.banner}>
      <div className={styles.bg_blur}></div>

      <div className={styles.top_container}>
        <a href="/">
          <div className={styles.logo}></div>
        </a>
        <WalletButton />
      </div>
      <div className={styles.middle_container}>
        <div className={styles.nft_info_container}>
          <div className={styles.nft_image}>
            <button
              className={styles.arrow_button}
              onClick={() => {
                if (itemIndex > 0) {
                  setItemIndex(itemIndex - 1);
                }
              }}
            >
              <img
                src="/img/ic_arrow_left.png"
                className="w-[22px] h-[22px]"
                alt="arrow"
              />
            </button>
            <img src={getItemImage()} className={styles.image} alt="nft" />
            <button
              className={styles.arrow_button}
              onClick={() => {
                if (itemIndex < nftInfoList.length - 1) {
                  setItemIndex(itemIndex + 1);
                }
              }}
            >
              <img
                src="/img/ic_arrow_right.png"
                alt="arrow"
                className="w-[22px] h-[22px]"
              />
            </button>
          </div>
          <div className={styles.nft_contents_container}>
            <span className={styles.nft_contents_title}>{getItemTitle()}</span>
            <span className={styles.nft_contents_text}>{getItemExp()}</span>
          </div>
        </div>
        <MintingInfo nftInfo={selectedNftInfo} refreshInfo={loadNFTInfos} />
      </div>
      <div className={styles.bottom_container}></div>
    </div>
  );
};
