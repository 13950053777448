import React, { useEffect } from 'react';
import { Banner } from './../components/banner/Banner';



const Minting = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Banner />
    );
};
export default Minting;