import React, { useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NFT1 from "../components/items/NFT1";
import { Button, Carousel, Table } from "antd";
import axios, { HttpStatusCode } from "axios";
import { api } from "../common/api";
import useScreenSize from "../hooks/useScreenSize";
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";
import { NoticePopup } from "../components/notice/NoticePopup";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3.5,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2.5,
      },
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1.5,
      },
    },
  ],
};

const PAGE_SIZE = 5;

const Home = () => {
  const navigate = useNavigate();

  const [intro, setIntro] = useState("");
  const [roadmap, setRoadmap] = useState("");

  const refHome = useRef(null);
  const refIntro = useRef(null);
  const refTheme = useRef(null);
  const refNFT = useRef(null);
  const refRoadmap = useRef(null);
  const refNotice = useRef(null);
  const refTeam = useRef(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const slider3 = useRef(null);
  const [noticeList, setNoticeList] = useState([]);
  const [noticeTotalCnt, setNoticeTotalCnt] = useState(0);
  const noticeTableColums = [
    {
      key: "Title",
      title: "",
      dataIndex: "Title",
    },
    {
      key: "CreateAt",
      title: "",
      dataIndex: "CreateAt",
      width: 70,
      render: (value) => (
        <p className="text-[12px] text-white text-right leading-[14px]">
          {value.substring(0, 10)}
        </p>
      ),
    },
  ];

  useEffect(() => {
    loadNoticeList(1, PAGE_SIZE);
    requestCmsInfo();
    window.scrollTo(0, 0);
  }, []);

  const loadNoticeList = (p_page, p_pageSize) => {
    setNoticeList([]);
    axios.get("/api/landings/notices/count").then((response) => {
      if (response.data.code == HttpStatusCode.Ok) {
        setNoticeTotalCnt(response.data.data);
        axios
          .get(`/api/landings/notices?page=${p_page}&limit=${p_pageSize}`)
          .then((response) => {
            if (response.data.code == HttpStatusCode.Ok) {
              setNoticeList(response.data.data);
            }
          });
      }
    });
  };

  const isInHome = useIsInViewport(refHome);
  const isInIntro = useIsInViewport(refIntro);
  const isInTheme = useIsInViewport(refTheme);
  const isInNFT = useIsInViewport(refNFT);
  const isInRoadMap = useIsInViewport(refRoadmap);
  const isInNotice = useIsInViewport(refNotice);
  const isInTeam = useIsInViewport(refTeam);

  useEffect(() => {
    try {
      document.getElementById("menu-buttons")?.childNodes?.forEach((it) => {
        it.className = "cursor-pointer text-[16px] text-[#A0A0A0]";
      });
      document.getElementById("menu-buttons-mo")?.childNodes?.forEach((it) => {
        it.className =
          "cursor-pointer leading-[16px] text-[16px] text-[#A0A0A0]";
      });
      if (isInTeam) {
        document.getElementById("menu-btn-team").className =
          "cursor-pointer text-[16px] text-white";
        document.getElementById("menu-btn-team-mo").className =
          "cursor-pointer leading-[16px] text-[16px] text-white";
      } else if (isInNotice) {
        document.getElementById("menu-btn-notice").className =
          "cursor-pointer text-[16px] text-white";
        document.getElementById("menu-btn-notice-mo").className =
          "cursor-pointer leading-[16px] text-[16px] text-white";
      } else if (isInRoadMap) {
        document.getElementById("menu-btn-roadmap").className =
          "cursor-pointer text-[16px] text-white";
        document.getElementById("menu-btn-roadmap-mo").className =
          "cursor-pointer leading-[16px] text-[16px] text-white";
      } else if (isInNFT) {
        document.getElementById("menu-btn-nft").className =
          "cursor-pointer text-[16px] text-white";
        document.getElementById("menu-btn-nft-mo").className =
          "cursor-pointer leading-[16px] text-[16px] text-white";
      } else if (isInTheme) {
        document.getElementById("menu-btn-theme").className =
          "cursor-pointer text-[16px] text-white";
        document.getElementById("menu-btn-theme-mo").className =
          "cursor-pointer leading-[16px] text-[16px] text-white";
      } else if (isInIntro) {
        document.getElementById("menu-btn-intro").className =
          "cursor-pointer text-[16px] text-white";
        document.getElementById("menu-btn-intro-mo").className =
          "cursor-pointer leading-[16px] text-[16px] text-white";
      } else if (isInHome) {
        document.getElementById("menu-btn-home").className =
          "cursor-pointer text-[16px] text-white";
        document.getElementById("menu-btn-home-mo").className =
          "cursor-pointer leading-[16px] text-[16px] text-white";
      }
    } catch (error) {
      return;
    }
  }, [
    isInHome,
    isInIntro,
    isInTheme,
    isInNFT,
    isInRoadMap,
    isInNotice,
    isInTeam,
  ]);

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(
          ([entry]) => setIsIntersecting(entry.isIntersecting),
          { threshold: 0.4 }
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  const requestCmsInfo = () => {
    axios.get("/api/landings/cms").then((response) => {
      if (response.data.code == HttpStatusCode.Ok) {
        setIntro(response.data.data.intro);
        setRoadmap(response.data.data.roadmap);
      }
    });
  };

  return (
    <div className="overflow-hidden">
      <section
        ref={refHome}
        id="home"
        className="h-[710px] flex items-center justify-center scroll-m-[100px] md:scroll-m-[150px]"
      >
        <img
          src="/img/img_landing_banner.png"
          className="w-full h-full object-cover"
        />
        <div className="absolute flex flex-col items-center mt-[-130px] md:mt-[-30px]">
          <p className="text-white text-[32px] md:text-[64px] leading-[46px] md:leading-[92px] font-[700]">
            규장각 디지털 헤리티지
          </p>
          <p className="text-white text-[24px] md:text-[40px] leading-[34px] md:leading-[58px] font-[500] mt-[21px]">
            제왕의 서가
          </p>
        </div>
      </section>
      <section
        ref={refIntro}
        id="intro"
        className="flex flex-col-reverse md:flex-row bg-white py-0 md:pt-[60px] md:pb-[130px] justify-center scroll-m-[100px] md:scroll-m-[150px]"
      >
        <div className="mx-[24px] mt-[50px] mb-[97px] md:m-0">
          <p className="text-[24px] font-black leading-[62px] text-[#494949]">
            소개
          </p>
          <p className="text-[12px] md:text-[16px] mt-[40px] md:mt-[54px] w-full md:w-[509px] text-[#494949] whitespace-pre-line break-keep tracking-tighter font-[700]">
            {intro}
          </p>
        </div>

        <img
          src="/img/img_introduce.png"
          className="w-full h-[480px] md:w-[381px] md:h-[428px] object-cover md:rounded-[30px] md:ml-[64px] md:mt-[116px] hidden md:block"
        />
      </section>

      <section
        ref={refTheme}
        id="theme"
        className="scroll-m-[100px] md:scroll-m-[150px]"
      >
        <Carousel
          autoplay
          dots={useScreenSize().width > 1200}
          autoplaySpeed={5000}
        >
          <div>
            <div className="h-[710px] flex flex-row justify-center relative">
              <img
                src="/img/img_landing_banner2.png"
                className="w-full h-full object-cover"
              />
              <div className="absolute flex flex-col md:flex-row items-center gap-y-[36px] md:gap-y-0 md:gap-x-[150px] px-[30px]">
                <img
                  src="/img/img_landing_banner2_1.png"
                  className="w-[194px] md:w-[363px]"
                />
                <div className="flex flex-col md:w-[543px] lg:w-[704px]">
                  <p className="text-white text-[14px] md:text-[26px] font-[500] leading-[20px] md:leading-[38px]">
                    규장각 고지도로 만나는
                  </p>
                  <p className="text-white text-[40px] md:text-[64px] font-[500] leading-[57px] md:leading-[92px]">
                    조선의 성곽
                  </p>
                  <p className="text-white text-[13px] md:text-[18px] font-[350] leading-[18px] md:leading-[26px] mt-[13px] md:mt-[28px] whitespace-pre-line break-keep">
                    조선 시대의 성곽과 주변의 산세, 그리고 성 내부의 건물들을{" "}
                    {useScreenSize().width > 1200 && <br />}
                    아름답게 담은 고지도 4종으로 구성되어 있습니다.
                    <br />
                    전주, 서울, 수원, 영변 등 역사적인 지역의 지리 정보를
                    {useScreenSize().width > 1200 && <br />}
                    회화적 요소와 결합하여 섬세하게 기록하고 묘사한
                    작품들입니다.
                    <br />
                    지방 행정과 경제의 중심인 읍성에서부터 수도 한양의 왕궁과
                    종묘사직을 지키기 위한 도성까지,
                    <br />
                    다양한 역할을 수행했던 조선의 옛 성들을 고지도로 만나보시기
                    바랍니다.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="h-[710px] flex flex-row justify-center relative">
              <img
                src="/img/img_landing_banner3.png"
                className="w-full h-full object-cover"
              />
              <div className="absolute flex flex-col md:flex-row items-center gap-y-[36px] md:gap-y-0 md:gap-x-[150px] px-[30px]">
                <img
                  src="/img/img_landing_banner3_1.png"
                  className="w-[194px] md:w-[363px]"
                />
                <div className="flex flex-col md:w-[543px] lg:w-[704px]">
                  <p className="text-black text-[14px] md:text-[26px] font-[500] leading-[20px] md:leading-[38px]">
                    규장각 고지도로 만나는
                  </p>
                  <p className="text-black text-[40px] md:text-[64px] font-[500] leading-[57px] md:leading-[92px]">
                    우리의 해안과 섬
                  </p>
                  <p className="text-black text-[13px] md:text-[18px] font-[350] leading-[18px] md:leading-[26px] mt-[13px] md:mt-[28px] whitespace-pre-line break-keep">
                    동해, 남해의 해안 지역과 섬을 세밀하게 표현한
                    {useScreenSize().width > 1200 && <br />}
                    고지도 5종으로 구성되어 있습니다.
                    <br />
                    기장, 거제, 영일, 울릉도, 원산 등의 지역이
                    {useScreenSize().width > 1200 && <br />}
                    산수화풍으로 생생하게 담겨 있습니다.
                    <br />
                    육지와 바다의 경계선 위주로 표현되는 현대 지도와 달리,
                    {useScreenSize().width > 1200 && <br />}
                    해안가와 바다의 수려한 자연경관을 회화적으로 표현한
                    {useScreenSize().width > 1200 && <br />}
                    우리의 고지도들을 통해
                    {useScreenSize().width > 1200 && <br />}그 시대의 미감을
                    느껴보시기 바랍니다.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="h-[710px] flex flex-row justify-center relative">
              <img
                src="/img/img_landing_banner4.png"
                className="w-full h-full object-cover"
              />
              <div className="absolute flex flex-col md:flex-row items-center gap-y-[36px] md:gap-y-0 md:gap-x-[150px] px-[30px]">
                <img
                  src="/img/img_landing_banner4_1.png"
                  className="w-[194px] md:w-[363px]"
                />
                <div className="flex flex-col md:w-[543px] lg:w-[704px]">
                  <p className="text-white text-[14px] md:text-[26px] font-[500] leading-[20px] md:leading-[38px]">
                    규장각 고지도로 만나는
                  </p>
                  <p className="text-white text-[40px] md:text-[64px] font-[500] leading-[57px] md:leading-[92px]">
                    남도의 풍광
                  </p>
                  <p className="text-white text-[13px] md:text-[18px] font-[350] leading-[18px] md:leading-[26px] mt-[13px] md:mt-[28px] whitespace-pre-line break-keep">
                    전라도의 주요 지역들을 정형화된 구도로,
                    {useScreenSize().width > 1200 && <br />}
                    그러나 화려하게 그려낸 고지도 3종으로 구성되어 있습니다.
                    <br />
                    광주, 순천, 장흥 등을 대상으로 해당 지역의 대표적인 경관과{" "}
                    {useScreenSize().width > 1200 && <br />}
                    그 당시 고을의 모습이 실감나게 표현되어 있습니다.
                    <br />
                    청록산수화풍으로 채색되어 높은 회화적 수준을 자랑하는{" "}
                    {useScreenSize().width > 1200 && <br />}
                    고지도들을 통해 남도의 옛 모습을 체험해 보시기 바랍니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </section>

      <section
        ref={refNFT}
        id="nft"
        className="pt-[50px] md:pt-[60px] pb-[50px] md:pb-[118px] flex flex-col items-center justify-center w-full bg-white scroll-m-[20px] md:scroll-m-[150px]"
      >
        <div className="flex flex-col w-full md:w-[905px]">
          <p className="text-[32px] leading-[62px] font-black text-[#494949] ml-[44px] md:ml-0 mb-[13px] md:mb-0">
            NFTs
          </p>
          <div className="flex flex-col mt-[27px] md:mt-[16px]">
            <div className="flex flex-row items-center justify-between">
              <p className="text-[24px] leading-[62px] font-black text-[#494949] ml-[44px] md:ml-0">
                조선의 성곽
              </p>
              {/* <div className='hidden md:flex flex-row gap-x-[20px] mr-[30px]'>
                                <button onClick={() => {
                                    slider1?.current.slickPrev()
                                }}>
                                    <img src='/img/ic_arrow_left.png' className='w-[24px] h-[24px]' />
                                </button>
                                <button onClick={() => {
                                    slider1?.current.slickNext()
                                }}>
                                    <img src='/img/ic_arrow_right.png' className='w-[24px] h-[24px]' />
                                </button>
                            </div> */}
            </div>
            <div className="ml-[24px] md:ml-0">
              <Slider {...sliderSettings} ref={slider1}>
                {[
                  "/img/nfts/img_nft1_1.png",
                  "/img/nfts/img_nft1_2.png",
                  "/img/nfts/img_nft1_3.png",
                  "/img/nfts/img_nft1_4.png",
                ].map((it, index) => {
                  return (
                    <div key={index}>
                      <NFT1 nftIcon={it} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div className="flex flex-col mt-[27px] md:mt-[16px]">
            <div className="flex flex-row items-center justify-between">
              <p className="text-[24px] leading-[62px] font-black text-[#494949] ml-[44px] md:ml-0">
                우리의 해안과 섬
              </p>
              <div className="hidden md:flex flex-row gap-x-[20px] mr-[30px]">
                <button
                  onClick={() => {
                    slider2?.current.slickPrev();
                  }}
                >
                  <img
                    src="/img/ic_arrow_left.png"
                    className="w-[24px] h-[24px]"
                  />
                </button>
                <button
                  onClick={() => {
                    slider2?.current.slickNext();
                  }}
                >
                  <img
                    src="/img/ic_arrow_right.png"
                    className="w-[24px] h-[24px]"
                  />
                </button>
              </div>
            </div>
            <div className="ml-[24px] md:ml-0">
              <Slider {...sliderSettings} ref={slider2}>
                {[
                  "/img/nfts/img_nft2_1.png",
                  "/img/nfts/img_nft2_2.png",
                  "/img/nfts/img_nft2_3.png",
                  "/img/nfts/img_nft2_4.png",
                  "/img/nfts/img_nft2_5.png",
                ].map((it, index) => {
                  return (
                    <div key={index}>
                      <NFT1 nftIcon={it} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div className="flex flex-col mt-[27px] md:mt-[16px]">
            <div className="flex flex-row items-center justify-between">
              <p className="text-[24px] leading-[62px] font-black text-[#494949] ml-[44px] md:ml-0">
                남도의 풍광
              </p>
              {/* <div className='hidden md:flex flex-row gap-x-[20px] mr-[30px]'>
                                <button onClick={() => {
                                    slider3?.current.slickPrev()
                                }}>
                                    <img src='/img/ic_arrow_left.png' className='w-[24px] h-[24px]' />
                                </button>
                                <button onClick={() => {
                                    slider3?.current.slickNext()
                                }}>
                                    <img src='/img/ic_arrow_right.png' className='w-[24px] h-[24px]' />
                                </button>
                            </div> */}
            </div>
            <div className="ml-[24px] md:ml-0">
              <Slider {...sliderSettings} ref={slider3}>
                {[
                  "/img/nfts/img_nft3_1.png",
                  "/img/nfts/img_nft3_2.png",
                  "/img/nfts/img_nft3_3.png",
                ].map((it, index) => {
                  return (
                    <div key={index}>
                      <NFT1 nftIcon={it} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={refRoadmap}
        id="roadmap"
        className="pt-[50px] md:pt-[70px] pb-[50px] md:pb-[135px] flex flex-col items-center justify-center w-full bg-white scroll-m-[100px] md:scroll-m-[150px]"
      >
        <div className="flex flex-col w-full md:w-[954px]">
          <p className="text-[24px] leading-[62px] font-black text-[#494949] mx-[24px] md:mx-0 px-[20px]">
            로드맵
          </p>
          <p className="text-[16px] font-medium leading-[30px] text-[#494949] mx-[24px] md:mx-0 mt-[40px] md:mt-[49px] whitespace-pre-line break-keep">
            {roadmap}
          </p>
          <div className="flex flex-col md:flex-row mt-[100px] md:mt-[67px] gap-y-[0px] md:gap-y-0 md:gap-x-[50px]">
            <div className="flex flex-col items-center h-[277px] md:h-[303px]">
              <div className="relative items-center justify-center flex w-[273px] h-[66px] rounded-[33px] bg-[#373737]">
                <p className="text-[20px] font-bold leading-[20px] text-white text-center">
                  문화재 NFT 발행
                </p>
              </div>
              <p className="mt-[50px] text-[#343434] text-[12px] leading-[20px]">
                - 규장각 소장 3개 테마 12종 <br />
                &nbsp;&nbsp;고지도를 NFT화하여 판매
                <br />- 다른 테마의 고지도 NFT 추가 발행
              </p>
            </div>

            <div className="flex flex-col items-center h-[277px] md:h-[303px]">
              <div className="relative items-center justify-center flex w-[273px] h-[66px] rounded-[33px] bg-[#373737]">
                <p className="text-[20px] font-bold leading-[24px] text-white text-center">
                  문화유산의 의미와 연계된
                  <br />
                  경험ᆞ참여 요소 제공
                </p>
              </div>
              <p className="mt-[50px] text-[#343434] text-[12px] leading-[20px]">
                - 고지도 외 다른 문화재에 대한 <br />
                &nbsp;&nbsp;NFT 발행
                <br />- NFT 구매자에 대한 혜택 제공
              </p>
            </div>

            <div className="flex flex-col items-center h-[277px] md:h-[303px]">
              <div className="relative items-center justify-center flex w-[273px] h-[66px] rounded-[33px] bg-[#373737]">
                <p className="text-[20px] font-bold leading-[24px] text-white text-center">
                  문화애호 커뮤니티 구축
                </p>
              </div>
              <p className="mt-[50px] text-[#343434] text-[12px] leading-[20px]">
                - NFT 보유 고객을 위한 <br />
                &nbsp;&nbsp;다양한 Activity 추진
                <br />- 문화재 멤버십 NFT로의 진화
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={refNotice}
        id="notice"
        className="h-[624px] md:h-[502px] flex flex-col items-center relative scroll-m-[100px] md:scroll-m-[150px]"
      >
        <img
          src="/img/img_notice_banner_pc.png"
          className="hidden md:block w-full h-full object-cover"
        />
        <img
          src="/img/img_notice_banner_mo.png"
          className="md:hidden w-full h-full object-cover"
        />
        <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center">
          <div className="flex flex-col-reverse md:flex-row w-full md:w-[954px] md:justify-between">
            <div className="pb-[30px] md:pb-[100px] flex flex-col">
              <p className="text-[24px] leading-[62px] font-black text-white mx-[24px] md:mx-0 px-[20px]">
                공지사항
              </p>
              <div className="px-[24px] md:px-0 mt-[28px] md:mt-[60px] notice-table">
                <p
                  className="text-white text-[15px] leading-[18px] mb-[12px] pr-[25px] md:pr-0 w-full text-right cursor-pointer"
                  onClick={() => {
                    navigate("/notice");
                  }}
                >
                  더보기
                </p>
                <Table
                  className="w-full md:w-[394px]"
                  columns={noticeTableColums}
                  dataSource={noticeList}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        navigate(`/notice-detail?Id=${record.Id}`);
                      },
                    };
                  }}
                  pagination={{
                    total: noticeTotalCnt,
                    pageSize: PAGE_SIZE,
                    position: ["bottomCenter"],
                    onChange: (page, pageSize) => [
                      loadNoticeList(page, pageSize),
                    ],
                  }}
                  rowKey="Title"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={refTeam}
        id="team"
        className="pt-[50px] md:pt-[70px] pb-[96px] md:pb-[108px] flex flex-col items-center justify-center w-full bg-white scroll-m-[100px] md:scroll-m-[150px]"
      >
        <div className="flex flex-col w-full md:w-[954px]">
          <p className="text-[24px] leading-[62px] font-black text-[#494949] mx-[24px] md:mx-0 px-[20px]">
            팀
          </p>
          <p className="text-[16px] font-[700] leading-[30px] text-[#494949] mx-[24px] md:mx-0 mt-[40px] whitespace-pre-line hidden md:block">
            ㈜엔에프티랩은 2023년 1월 설립되었으며, &lt;규장각 디지털
            헤리티지&gt; 프로젝트의 운영을 맡고 있습니다.
            <br />
            본질 가치가 높은 다양한 형태의 문화자산을 효용성과 커뮤니티에 기반한
            NFT로 제공하는 것을 목표로 하고 있습니다.
            <br />
            <br />
            규장각한국학연구원은 2006년 기존의 서울대학교 규장각과
            한국문화연구소가 하나의 기관으로 통합되어 출범하였습니다.
            <br />
            &lt;규장각 디지털 헤리티지&gt; 프로젝트에서 제공될 NFT의 원본 문화재
            소장처이며,
            <br />
            국내외 한국학연구의 진흥과 소장 자료의 과학적 보존·관리에 매진하고
            있습니다.
          </p>
          <p className="text-[16px] font-[700] leading-[30px] text-[#494949] mx-[24px] md:mx-0 mt-[40px] whitespace-pre-line block md:hidden">
            ㈜엔에프티랩은 2023년 1월 설립되었으며,
            <br />
            &lt;규장각 디지털 헤리티지&gt; 프로젝트의 운영을
            <br />
            맡고 있습니다.
            <br />
            본질 가치가 높은 다양한 형태의 문화자산을 효용성과 커뮤니티에 기반한
            NFT로 제공하는 것을
            <br />
            목표로 하고 있습니다.
            <br />
            <br />
            규장각한국학연구원은 2006년 기존의
            <br />
            서울대학교 규장각과 한국문화연구소가 하나의
            <br />
            기관으로 통합되어 출범하였습니다.
            <br />
            &lt;규장각 디지털 헤리티지&gt; 프로젝트에서
            <br />
            제공될 NFT의 원본 문화재 소장처이며,
            <br />
            국내외 한국학연구의 진흥과 소장 자료의 과학적 보존·관리에 매진하고
            있습니다.
          </p>
          <div className="flex flex-col md:flex-row mt-[30px] md:mt-[52px] gap-y-[44px] md:gap-y-0 md:gap-x-[67px] items-center justify-center">
            <div className="flex flex-col items-center">
              <div className="relative items-center justify-center flex">
                <img
                  src="/img/ic_nft_lab.png"
                  className="w-[251px] h-[154px]"
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="relative items-center justify-center flex">
                <img
                  src="/img/ic_kyujang_studio.png"
                  className="w-[251px] h-[154px]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <NoticePopup />
    </div>
  );
};
export default Home;
