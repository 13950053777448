import { Button } from 'antd';
import React from 'react';

const BtnCategory = (props) => {
    return (
        <Button onClick={props.onClick} className={props.selected ? 'h-[22px] px-[21px] py-0 text-white text-[12px] font-bold rounded-[11px] bg-[#525252]' : 'h-[22px] px-[21px] py-0 text-white text-[12px] font-bold rounded-[11px] bg-[#AEAEAE]'}>
            {props.title}
        </Button >
    );
};
export default BtnCategory;