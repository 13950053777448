import { Button, Image, Modal } from "antd";
import axios, { HttpStatusCode } from "axios";
import React, { useEffect, useRef, useState } from "react";
import BtnCategory from "../components/btn/BtnCategory";
import NFT2 from "../components/items/NFT2";
import useIsMobile from "../hooks/useIsMobile";
import useScreenSize from "../hooks/useScreenSize";
import { useMetamask } from "../hooks/useMetamask";
import { Loading } from "../components/loading/Loading";

const Holder = () => {
  const [categories, setCategories] = useState([
    { name: "전체보기", selected: true, value: 0 },
    { name: "조선의 성곽", selected: false, value: 1 },
    { name: "우리의 해안과 섬", selected: false, value: 2 },
    { name: "남도의 풍광", selected: false, value: 3 },
  ]);
  const [allList, setAllList] = useState([]);
  const [nftList, setNftList] = useState([]);
  const [totalNftCnt, setTotalNftCnt] = useState(0);
  const [loadingEnd, setLoadingEnd] = useState(true);
  const [detailInfo, setDetailInfo] = useState(null);
  const { selectedAccount, connectWallet } = useMetamask();
  const [loading, setLoading] = useState(false);

  const curPage = useRef(1);

  const screenSize = useScreenSize();

  const [imgPreviewVisible, setImgPreviewVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = (p_tokenId) => {
    axios.get(`/api/holders/nft?tokenId=${p_tokenId}`).then((response) => {
      if (response.data.code === HttpStatusCode.Ok) {
        setDetailInfo(response.data.data);
        setOpen(true);
      }
    });
  };
  const handleOk = (e) => {
    console.log(e);
    setOpen(false);
  };
  const handleCancel = (e) => {
    console.log(e);
    setOpen(false);
  };

  useEffect(() => {
    console.log(selectedAccount);
    loadAllList();
    window.scrollTo(0, 0);
  }, [selectedAccount]);

  useEffect(() => {
    setLoadingEnd(true);
    setNftList([]);
    loadNftList(1);
  }, [categories, allList]);

  const loadAllList = () => {
    if (!selectedAccount) {
      return;
    }

    setLoading(true);

    axios
      .get(`/api/holders/total?address=${selectedAccount}`)
      .then((response) => {
        if (response.data.code == HttpStatusCode.Ok) {
          setTotalNftCnt(response.data.data);
        }
      });
    axios
      // .get(`/api/holders/nfts?address=${"0x532a1d1b9241005F6C3197F3a1486F232f4aa85F"}&category=0`)
      .get(`/api/holders/nfts?address=${selectedAccount}&category=0`)
      .then((response) => {
        if (response.data.code == HttpStatusCode.Ok) {
          setAllList(response.data.data);
          setLoading(false);
        }
      });
  };

  const loadNftList = (p_page) => {
    curPage.current = p_page;
    const limit = screenSize.width > 1000 ? 12 : 8;
    const filterCategoryList =
      categories.find((it) => it.selected).value == 0
        ? allList
        : allList.filter(
            (it) =>
              it.categoryVal === categories.find((it) => it.selected).value
          );
    const pageList = filterCategoryList.slice(
      (p_page - 1) * limit,
      p_page * limit
    );
    setNftList((prevState) => [...prevState, ...pageList]);
    if (pageList.length < limit) {
      setLoadingEnd(true);
    } else {
      setLoadingEnd(false);
    }
  };

  const getOriginalImageUrl = (url) => {
    if (!url) {
      return "";
    }

    const splitUrl = url.split("/");
    const fileName = splitUrl[splitUrl.length - 1];
    const originalUrl = url.replace(fileName, `original/${fileName}`);
    return originalUrl;
  };

  return (
    <div className="overflow-hidden bg-white pt-[38px] md:pt-[68px] flex flex-col items-center">
      <section className="px-[24px] md:px-0 flex flex-col w-full max-w-[915px]">
        {selectedAccount ? (
          <Button
            className="text-[16px] text-white font-[700] bg-[#525252] h-[36px] rounded-[18px] px-[30px] self-end"
            onClick={() => {
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSd0ccKU7DD8AzkKJCSHceWlZYAseeuYc3uq1O2a88rsNbhNwA/viewform?usp=sf_link",
                "_blank"
              );
            }}
          >
            영인본 신청
          </Button>
        ) : (
          <Button
            className="text-[16px] text-white font-[700] bg-[#525252] h-[36px] rounded-[18px] px-[30px] self-end"
            onClick={() => connectWallet()}
          >
            지갑연결
          </Button>
        )}
        <div className="w-full h-[440px] md:h-[309px] mt-[34px] md:mt-[14px] relative">
          <img
            src="/img/img_holder_banner.png"
            className="w-full h-full object-cover z-10"
          />
          <div className="absolute left-0 right-0 top-0 bottom-0 flex flex-col items-center justify-center">
            <p className="text-[24px] md:text-[36px] font-bold text-white">
              규장각 디지털 헤리티지
            </p>
            <p className="text-[20px] md:text-[24px] font-medium text-white">
              제왕의 서가
            </p>
          </div>
        </div>
      </section>

      <section className="mt-[16px] md:mt-[41px] flex flex-col md:flex-row md:items-center w-full max-w-[915px] pl-[24px] md:pl-[14px]">
        <div className="flex flex-row gap-x-[15px] overflow-x-auto pb-[6px] md:pb-0 pr-[24px] flex-1">
          {categories.map((it, index) => {
            return (
              <BtnCategory
                title={it.name}
                key={index}
                selected={it.selected}
                onClick={() => {
                  const tmp = JSON.parse(JSON.stringify(categories));
                  tmp.map((it) => {
                    it.selected = false;
                  });
                  tmp[index].selected = true;
                  setCategories(tmp);
                }}
              />
            );
          })}
        </div>
        <p className="text-right mr-[24px] md:mr-[15px] text-[8px] md:text-[12px] text-[#525252] font-[600] leading-[24px]">
          보유 NFT : {totalNftCnt}개
        </p>
      </section>

      <div className="h-[1px] bg-[#C5C5C5] w-[calc(100vw-48px)] md:w-[915px] md:mt-[11px]" />

      <section className="px-[24px] md:px-0 w-full max-w-[915px]">
        {nftList.length > 0 ? (
          <div className="pt-[19px] md:pt-[26px] pb-[38px] md:pb-[127px] flex flex-col">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-[5px] md:gap-x-[25px] gap-y-[11px]">
              {nftList.map((it, index) => {
                return (
                  <div className="flex items-center justify-center" key={index}>
                    <NFT2
                      item={it}
                      onClick={() => {
                        showModal(it.tokenId);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            {!loadingEnd && (
              <Button
                className="bg-[#525252] h-[36px] rounded-[18px] text-white text-[16px] font-bold self-center mt-[32px] md:mt-[54px]"
                onClick={() => loadNftList(curPage.current + 1)}
              >
                더보기
              </Button>
            )}
          </div>
        ) : (
          <div className="mt-[144px] mb-[199px] md:mt-[132px] md:mb-[216px]">
            {loading ? (
              <div className="w-full flex items-center justify-center">
                <Loading />
              </div>
            ) : (
              <p className="text-center text-[16px] font-bold text-[#B9B9B9]">
                보유한 NFT가 없습니다.
              </p>
            )}
          </div>
        )}
      </section>

      <Modal
        title=""
        open={open}
        onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        styles={{
          content: { padding: 0, borderRadius: 20, overflow: "hidden" },
        }}
        width={915}
      >
        <div className="flex flex-col md:flex-row md:h-[500px]">
          <div className="w-full md:w-[600px] bg-black relative">
            <Image
              width={useIsMobile() ? screenSize.width - 24 : 600}
              height={useIsMobile() ? 334 : 500}
              className="w-full h-[334px] md:h-full object-contain"
              src={detailInfo?.imageUrl}
              preview={{
                visible: imgPreviewVisible,
                mask: <div className='flex flex-row items-center'><svg className="mr-[3px]" viewBox="64 64 896 896" focusable="false" data-icon="eye" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg>크게보기</div>,
                src: getOriginalImageUrl(detailInfo?.imageUrl),
                onVisibleChange: (value) => {
                  setImgPreviewVisible(value);
                },
              }}
            />
            <img
              src="/img/ic_search_zoom_in.png"
              className="w-[24px] h-[24px] absolute bottom-[10px] right-[10px] md:bottom-[15px] md:right-[15px] cursor-pointer"
              alt="zoom"
              onClick={() => {
                setImgPreviewVisible(true);
              }}
            />
          </div>
          <div className="w-full md:w-[315px] md:h-full flex-col pt-[17px] md:pt-[15px] pb-[43px] px-[24px]">
            <p className="text-[15px] font-bold text-black">
              {detailInfo?.title}
            </p>
            <div className="w-full h-[1px] bg-[#D0D0D0] mt-[9px] mb-[12px]" />

            <div className="flex flex-col h-[420px] overflow-auto pr-[5px]">
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <img
                    src="/img/ic_archive.png"
                    alt="archive"
                    className="w-[14px] h-[14px]"
                  />
                  <p className="text-[11px] leading-[13px] font-bold text-black ml-[10px]">
                    Description
                  </p>
                </div>
                <div className="w-full px-[12px] py-[8px] rounded-[5px] border-[1px] border-[#B0B0B0] mt-[5px]">
                  <p className="text-[11px] font-medium text-[#525252] leading-[18.5px] whitespace-pre-line">
                    {detailInfo?.description}
                  </p>
                </div>
              </div>

              <div className="flex flex-col mt-[29px]">
                <div className="flex flex-row items-center">
                  <img
                    src="/img/ic_desc.png"
                    className="w-[14px] h-[14px]"
                    alt="desc"
                  />
                  <p className="text-[11px] leading-[13px] font-bold text-black ml-[10px]">
                    Details
                  </p>
                </div>
                <div className="w-full px-[12px] py-[8px] rounded-[5px] border-[1px] border-[#B0B0B0] mt-[5px]">
                  {detailInfo?.details.map((it) => {
                    return (
                      <div className="w-full flex flex-row justify-between">
                        <p className="text-[11px] font-medium text-[#525252] leading-[18.5px] w-[50px]">
                          {it.key}
                        </p>
                        <p className="text-[11px] font-medium text-[#525252] leading-[18.5px] text-right">
                          {it.value}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <img
            src="/img/ic_close_white.png"
            alt="close"
            className="w-[20px] h-[20px] absolute top-[16px] right-[13px] cursor-pointer block md:hidden"
            onClick={handleCancel}
          />
          <img
            src="/img/ic_close_black.png"
            alt="close"
            className="w-[20px] h-[20px] absolute top-[17px] right-[20px] cursor-pointer hidden md:block"
            onClick={handleCancel}
          />
        </div>
      </Modal>
    </div>
  );
};
export default Holder;
