import React from "react";

import { useMetamask } from "../../hooks/useMetamask";
import styles from "./Wallet.module.scss";

function getShortAddress(addr) {
  if (!addr) return "";
  return addr.substring(0, 8) + "..." + addr.substring(addr.length - 6);
}

export const WalletButton = () => {
  const { connectWallet, selectedAccount, error, loading } = useMetamask();

  if (loading) {
    return <div className={styles.connect_btn}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.connect_btn}>{error}</div>;
  }

  return (
    <div
      className={`${styles.connect_btn} ${
        selectedAccount ? null : styles.cursor
      }`}
      onClick={selectedAccount ? null : () => connectWallet()}
    >
      {selectedAccount ? getShortAddress(selectedAccount) : "지갑연결"}
    </div>
  );
};
