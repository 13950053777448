import { Button, Form, Input, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditableCell } from './EditableCell';
import axios, { HttpStatusCode } from 'axios';
import { TOKEN } from '../../common/api';
import { WalletInsertTable } from './WalletInsertTable';

const WALLET_PAGE_SIZE = 15

export const WalletTable = ({
  type,
  openNotificationWithIcon
}) => {

  const columnTypes = {
    "minting": [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>전주지도</div>,
        dataIndex: '1M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 경도</div>,
        dataIndex: '1M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 수원부</div>,
        dataIndex: '1M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>철옹성전도</div>,
        dataIndex: '1M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 기장현</div>,
        dataIndex: '2M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제부</div>,
        dataIndex: '2M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 영일현</div>,
        dataIndex: '2M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>해동지도 중 울릉도</div>,
        dataIndex: '2M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>원산지도</div>,
        dataIndex: '2M5',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>광주지도</div>,
        dataIndex: '3M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>순천부지도</div>,
        dataIndex: '3M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>장흥부지도</div>,
        dataIndex: '3M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
        dataIndex: 'SUM',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
    ],
    "account": [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>계좌번호</div>,
        dataIndex: 'Memo',
        width: 100,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>전주지도</div>,
        dataIndex: '1M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 경도</div>,
        dataIndex: '1M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 수원부</div>,
        dataIndex: '1M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>철옹성전도</div>,
        dataIndex: '1M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 기장현</div>,
        dataIndex: '2M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제부</div>,
        dataIndex: '2M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 영일현</div>,
        dataIndex: '2M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>해동지도 중 울릉도</div>,
        dataIndex: '2M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>원산지도</div>,
        dataIndex: '2M5',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>광주지도</div>,
        dataIndex: '3M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>순천부지도</div>,
        dataIndex: '3M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>장흥부지도</div>,
        dataIndex: '3M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
        dataIndex: 'SUM',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: ' ',
        dataIndex: 'operation',
        width: 60,
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Button
                className='bg-[blue] text-[white] w-[40px] h-[16px] text-[10px] leading-[8px] rounded-[8px] p-0'
                onClick={() => save(record.WalletAddress)}
              >
                저장
              </Button>
              <Popconfirm title="취소하시겠어요?" okButtonProps={{ className: "bg-[blue]" }} onConfirm={cancel}>
                <Button className='w-[40px] h-[16px] text-[10px] leading-[8px] rounded-[8px] p-0'>
                  취소
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <Button className='bg-[#CDFFC8] w-[40px] h-[16px] text-[10px] leading-[8px] rounded-[8px] p-0' disabled={editingKey !== ''} onClick={() => edit(record)}>
              수정
            </Button>
          );
        },
      },
    ],
    "airdrop": [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 연변부</div>,
        dataIndex: '1S1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>동국여도 중 북한성도</div>,
        dataIndex: '1S2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 전주지도</div>,
        dataIndex: '1S3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제영등진지도</div>,
        dataIndex: '2S1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>울릉도외도</div>,
        dataIndex: '2S2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>울릉도도형</div>,
        dataIndex: '2S3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>호남지도 중 해남</div>,
        dataIndex: '3S1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>호남지도 중 장성부</div>,
        dataIndex: '3S2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>송광사사적</div>,
        dataIndex: '3S3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>비고</div>,
        dataIndex: 'Memo',
        width: 100,
        editable: true,
      },
      // {
      //   title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
      //   dataIndex: 'SUM',
      //   editable: true,
      //   render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      // },
      {
        title: ' ',
        dataIndex: 'operation',
        width: 60,
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Button
                className='bg-[blue] text-[white] w-[40px] h-[16px] text-[10px] leading-[8px] rounded-[8px] p-0'
                onClick={() => save(record.WalletAddress)}
              >
                저장
              </Button>
              <Popconfirm title="취소하시겠어요?" okButtonProps={{ className: "bg-[blue]" }} onConfirm={cancel}>
                <Button className='w-[40px] h-[16px] text-[10px] leading-[8px] rounded-[8px] p-0'>
                  취소
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <Button className='bg-[#CDFFC8] w-[40px] h-[16px] text-[10px] leading-[8px] rounded-[8px] p-0' disabled={editingKey !== ''} onClick={() => edit(record)}>
              수정
            </Button>
          );
        },
      },
    ]
  }

  const totalCntColmnTypes = {
    "minting": [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>전주지도</div>,
        dataIndex: '1M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 경도</div>,
        dataIndex: '1M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 수원부</div>,
        dataIndex: '1M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>철옹성전도</div>,
        dataIndex: '1M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 기장현</div>,
        dataIndex: '2M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제부</div>,
        dataIndex: '2M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 영일현</div>,
        dataIndex: '2M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>해동지도 중 울릉도</div>,
        dataIndex: '2M4',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>원산지도</div>,
        dataIndex: '2M5',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>광주지도</div>,
        dataIndex: '3M1',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>순천부지도</div>,
        dataIndex: '3M2',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>장흥부지도</div>,
        dataIndex: '3M3',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
        dataIndex: 'SUM',
        editable: true,
        render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      },
    ],
    "account": [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>계좌번호</div>,
        dataIndex: 'Memo',
        width: 100,
        render: (text, record, index) => { return <div>총 NFT 수량</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>전주지도</div>,
        dataIndex: '1M1',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#FFA6A6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 경도</div>,
        dataIndex: '1M2',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#FFA6A6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 수원부</div>,
        dataIndex: '1M3',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#FFA6A6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>철옹성전도</div>,
        dataIndex: '1M4',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#FFA6A6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 기장현</div>,
        dataIndex: '2M1',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제부</div>,
        dataIndex: '2M2',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 영일현</div>,
        dataIndex: '2M3',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>해동지도 중 울릉도</div>,
        dataIndex: '2M4',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>원산지도</div>,
        dataIndex: '2M5',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>광주지도</div>,
        dataIndex: '3M1',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#A6C9FF]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>순천부지도</div>,
        dataIndex: '3M2',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#A6C9FF]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>장흥부지도</div>,
        dataIndex: '3M3',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#A6C9FF]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
        dataIndex: 'SUM',
        editable: true,
      },
      {
        title: ' ',
        dataIndex: 'operation',
        width: 60,
      },
    ],
    "airdrop": [
      {
        title: (filters, sortOrder) => <div className='bg-[#A6FFE4]'>지갑주소</div>,
        dataIndex: 'WalletAddress',
        width: 100,
        render: (text, record, index) => { return <div>총 NFT 수량</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>해동지도 중 연변부</div>,
        dataIndex: '1S1',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#FFA6A6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>동국여도 중 북한성도</div>,
        dataIndex: '1S2',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#FFA6A6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#FFA6A6]'>지방지도 중 전주지도</div>,
        dataIndex: '1S3',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#FFA6A6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>지방지도 중 거제영등진지도</div>,
        dataIndex: '2S1',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>울릉도외도</div>,
        dataIndex: '2S2',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#DDFFA6]'>울릉도도형</div>,
        dataIndex: '2S3',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#DDFFA6]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>호남지도 중 해남</div>,
        dataIndex: '3S1',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#A6C9FF]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>호남지도 중 장성부</div>,
        dataIndex: '3S2',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#A6C9FF]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#A6C9FF]'>송광사사적</div>,
        dataIndex: '3S3',
        editable: true,
        render: (text, record, index) => { return <div className='bg-[#A6C9FF]'>{text}</div> }
      },
      {
        title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>비고</div>,
        dataIndex: 'Memo',
        width: 100,
        editable: true,
      },
      // {
      //   title: (filters, sortOrder) => <div className='bg-[#C7C7C7]'>계</div>,
      //   dataIndex: 'SUM',
      //   editable: true,
      //   render: (text, record, index) => { return <div className={text == 0 ? 'text-[black]' : 'text-[#002AFF]'}>{text}</div> }
      // },
      {
        title: ' ',
        dataIndex: 'operation',
        width: 60,
        render: (_, record) => {

        },
      },
    ]
  }

  const mergedColumns = columnTypes[type].map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const [walletTotalCnt, setWalletTotalCnt] = useState(0)
  const [walletList, setWalletList] = useState([])
  const [walletCntList, setWalletCntList] = useState([])

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    cancel()
    setSearchKey("")
    loadWalletList(1, WALLET_PAGE_SIZE)
  }, [type])

  const isEditing = (record) => record.WalletAddress === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.WalletAddress);
  };
  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...walletList];
      const index = newData.findIndex((item) => key === item.WalletAddress);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setWalletList(newData);
        setEditingKey('');

        const res = await axios.put(`/api/admins/${type}`, {
          info: JSON.stringify([{ ...item, ...row, }]),
        }, {
          headers: {
            "x-access-token": TOKEN
          }
        })
        if (res.data.code == HttpStatusCode.Ok) {
          openNotificationWithIcon('success', "조작이 성공하였습니다.")
        }


      } else {
        newData.push(row);
        setWalletList(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };



  const loadWalletList = async (p_page, p_pageSize) => {
    setWalletList([])
    const res1 = await axios.get(`/api/admins/info/count?type=${type}`,
      {
        headers: {
          "x-access-token": TOKEN
        }
      })
    if (res1.data.code == HttpStatusCode.Ok) {
      setWalletTotalCnt(res1.data.data)
    }

    const res2 = await axios.get(`/api/admins/info?type=${type}&page=${p_page}&limit=${p_pageSize}`,
      {
        headers: {
          "x-access-token": TOKEN
        }
      })
    if (res2.data.code == HttpStatusCode.Ok) {
      const tmp = res2.data.data
      tmp.map(it => {
        it.SUM = 0 + Number(it['1M1'] ?? 0) + Number(it['1M2'] ?? 0) + Number(it['1M3'] ?? 0) + Number(it['1M4'] ?? 0) + Number(it['1M5'] ?? 0)
          + Number(it['2M1'] ?? 0) + Number(it['2M2'] ?? 0) + Number(it['2M3'] ?? 0) + Number(it['2M4'] ?? 0) + Number(it['2M5'] ?? 0)
          + Number(it['3M1'] ?? 0) + Number(it['3M2'] ?? 0) + Number(it['3M3'] ?? 0) + Number(it['3M4'] ?? 0) + Number(it['3M5'] ?? 0)
      })
      setWalletList(tmp)
    }

    if (type != "minting") {
      const res3 = await axios.get(`/api/admins/total?type=${type}`,
        {
          headers: {
            "x-access-token": TOKEN
          }
        })
      if (res3.data.code == HttpStatusCode.Ok) {
        setWalletCntList(res3.data.data)
      }
    }
  }

  const searchWalletList = async (p_page, p_pageSize) => {
    setWalletList([])
    const res1 = await axios.get(`/api/admins/info/count/search?type=${type}&keyword=${searchKey}`,
      {
        headers: {
          "x-access-token": TOKEN
        }
      })
    if (res1.data.code == HttpStatusCode.Ok) {
      setWalletTotalCnt(res1.data.data)
    }

    const res2 = await axios.get(`/api/admins/info/search?type=${type}&page=${p_page}&limit=${p_pageSize}&keyword=${searchKey}`,
      {
        headers: {
          "x-access-token": TOKEN
        }
      })
    if (res2.data.code == HttpStatusCode.Ok) {
      const tmp = res2.data.data
      tmp.map(it => {
        it.SUM = 0 + Number(it['1M1'] ?? 0) + Number(it['1M2'] ?? 0) + Number(it['1M3'] ?? 0) + Number(it['1M4'] ?? 0) + Number(it['1M5'] ?? 0)
          + Number(it['2M1'] ?? 0) + Number(it['2M2'] ?? 0) + Number(it['2M3'] ?? 0) + Number(it['2M4'] ?? 0) + Number(it['2M5'] ?? 0)
          + Number(it['3M1'] ?? 0) + Number(it['3M2'] ?? 0) + Number(it['3M3'] ?? 0) + Number(it['3M4'] ?? 0) + Number(it['3M5'] ?? 0)
      })
      setWalletList(tmp)
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row items-center mt-[-90px] justify-end'>
        <div className='flex flex-row w-[300px] h-[28px] items-center relative'>
          <Input className='h-full rounded-[14px] bg-[#D9D9D9]' value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
          <Button className='absolute right-0 bg-[#A6C9FF] py-[0px] h-[26px] rounded-[14px]' disabled={!searchKey} onClick={() => {
            searchWalletList(1, WALLET_PAGE_SIZE)
          }}>검색</Button>
        </div>
      </div>
      <div className='mt-[60px] admin-wallet-table'>
        <Form form={form} component={false}>
          <Table columns={mergedColumns} dataSource={walletList}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            pagination={{
              total: Math.max(1, walletTotalCnt),
              pageSize: WALLET_PAGE_SIZE,
              position: ["bottomCenter"],
              onChange: (page, pageSize) => {
                cancel()
                loadWalletList(page, pageSize)
              }
            }}
            rowKey="WalletAddress"
          />
        </Form>
      </div>

      {type != "minting" &&
        <div className='flex flex-col w-full'>
          <div className='mt-[-120px] mb-[120px] admin-wallet-below-table'>
            <Table columns={totalCntColmnTypes[type]} dataSource={walletCntList}
              pagination={{
                total: 1,
                pageSize: 1,
                position: ["none"],
              }} />
          </div>
          <div className='w-full my-[30px] border-b-[1px] border-[#E4E4E4]'></div>

          <div className='bg-[#D5D5D5] text-[black] text-[12px] px-[10px] h-[30px] rounded-[15px] leading-[30px] ml-[100px] self-start'>
            입력
          </div>

          <div className='admin-wallet-table mt-[20px]'>
            <WalletInsertTable type={type} openNotificationWithIcon={openNotificationWithIcon} reloadData={() => {
              setSearchKey("")
              loadWalletList(1, WALLET_PAGE_SIZE)
            }} />
          </div>
        </div>
      }
    </div>
  );
};
