// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loader__IpArE {
  position: relative;
  transform: translateZ(0);
  animation: Loading_loader__IpArE 1.1s infinite linear;
}

@keyframes Loading_loader__IpArE {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loading/Loading.module.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,wBAAA;EACA,qDAAA;AADJ;;AASA;EACI;IACI,uBAAA;EANN;EASE;IACI,yBAAA;EAPN;AACF","sourcesContent":["@import \"../../styles/mixins.scss\";\n\n.loader {\n    position: relative;\n    transform: translateZ(0);\n    animation: loader 1.1s infinite linear;\n\n    @include breakpoint(d) {\n        width: 25px;\n        height: 25px;\n    }\n}\n\n@keyframes loader {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loading_loader__IpArE`
};
export default ___CSS_LOADER_EXPORT___;
