// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuBtn button ~ button {
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(205 205 205 / var(--tw-border-opacity));
    background-color: transparent;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.menuBtn button {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEI;IAAA,iBAAA;IAAA,sBAAA;IAAA,yDAAA;IAAA,6BAAA;IAAA,oBAAA;IAAA;AAAA;AAGA;IAAA,kBAAA;IAAA,yDAAA;IAAA,oBAAA;IAAA;AAAA","sourcesContent":[".menuBtn {\r\n  button ~ button {\r\n    @apply border-[2px] border-[#CDCDCD] bg-transparent text-white;\r\n  }\r\n  button {\r\n    @apply bg-[white] text-black;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
