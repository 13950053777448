import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import ScrollToAnchor from "./ScrollToAnchor";

const Layout = () => {
  return (
    <div>
      <Header />
      <div className="pt-[60px] md:pt[71px]">
        <ScrollToAnchor />
        <Outlet />
      </div>
      <Footer />
    </div>
  )
};

export default Layout;