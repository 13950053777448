import { toast } from "react-toastify";

import { mintorContract } from "../utility/contract";
import Web3 from "web3";

export const mint = async (
  contractAddress,
  id,
  cost,
  amount,
  onSuccess,
  openModal
) => {
  // TODO Do a check if we are the owner of this token
  const mintPromise = async () => {
    const transactionParameters = {
      to: contractAddress,
      from: window.ethereum.selectedAddress,
      data: mintorContract().methods.mint(id, amount).encodeABI(),
      value: Web3.utils.toHex(cost * amount),
    };
    //sign the transaction via Metamask
    try {
      // Could grab txtHash from this
      await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      onSuccess(true);
      openModal("");
      // TODO - wait for contract to complete somehow, keeping our toast notification there untill it is finished
      // TODO - put some values in the context, so when this completes we fire of an event to update the context
    } catch (error) {
      console.log({ error });
      onSuccess(false);
      openModal(error.message);
      throw error;
    }
  };

  const options = {
    autoClose: 5000,
    hideProgressBar: false,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
  };

  toast.promise(
    mintPromise,
    {
      pending: "Pending the transaction",
      success: "Success, you should receive your mint shortly",
      error: "Whoops, looks like something went wrong.",
    },
    options
  );
};
