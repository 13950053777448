import { Button, Table } from "antd";
import axios, { HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PAGE_SIZE = 10;

const Notice = () => {
  const navigate = useNavigate();
  const [noticeList, setNoticeList] = useState([]);
  const [noticeTotalCnt, setNoticeTotalCnt] = useState(0);
  const noticeTableColums = [
    {
      key: "Title",
      title: "",
      dataIndex: "Title",
      render: (value) => (
        <p className="text-[16px] font-[700] text-black leading-[24px]">
          {value}
        </p>
      ),
    },
    {
      key: "CreateAt",
      title: "",
      dataIndex: "CreateAt",
      width: 90,
      render: (value) => (
        <p className="text-[14px] text-black text-right leading-[24px]">
          {value.substring(0, 10)}
        </p>
      ),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    loadNoticeList(1, PAGE_SIZE);
  }, []);

  const loadNoticeList = (p_page, p_pageSize) => {
    setNoticeList([]);
    axios.get("/api/landings/notices/count").then((response) => {
      if (response.data.code == HttpStatusCode.Ok) {
        setNoticeTotalCnt(response.data.data);
        axios
          .get(`/api/landings/notices?page=${p_page}&limit=${p_pageSize}`)
          .then((response) => {
            if (response.data.code == HttpStatusCode.Ok) {
              setNoticeList(response.data.data);
            }
          });
      }
    });
  };

  return (
    <div className="overflow-hidden bg-white flex flex-col">
      <section className="flex flex-col items-center px-[24px] md:px-0 py-[63px] md:py-[115px] w-full md:w-[910px] self-center">
        <p className="text-[36px] md:text-[48px] leading-[56px] md:leading-[71px] font-[700] text-black tracking-tight self-start">
          공지사항
        </p>
        <div className="mt-[32px] notice-table2 w-full">
          <Table
            className="w-full"
            columns={noticeTableColums}
            dataSource={noticeList}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  navigate(`/notice-detail?Id=${record.Id}`);
                },
              };
            }}
            pagination={{
              total: noticeTotalCnt,
              pageSize: PAGE_SIZE,
              position: ["bottomCenter"],
              onChange: (page, pageSize) => [loadNoticeList(page, pageSize)],
            }}
            rowKey="Title"
          />
        </div>
        <Button
          className="self-end bg-[#525252] text-white rounded-[20px] w-[102px] h-[35px] mt-[15px]"
          onClick={() => {
            navigate("/#notice");
          }}
        >
          처음으로
        </Button>
      </section>
    </div>
  );
};
export default Notice;
