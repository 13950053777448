import { useEffect, useState } from "react";

import Web3 from "web3";

import { convertFromHexDecimal } from "../utility/helpers";
import axios, { HttpStatusCode } from "axios";
import { toast } from "react-toastify";

const networkInfo = {
  1001: {
    name: "Klaytn Baobab",
    rpcUrl: "https://api.baobab.klaytn.net:8651",
    blockExplorerUrls: "https://baobab.scope.klaytn.com/",
    nativeCurrency: {
      name: "KLAY",
      symbol: "KLAY",
      decimals: 18,
    },
  },
  8217: {
    name: "Klaytn Cypress",
    rpcUrl: "https://public-en-cypress.klaytn.net",
    blockExplorerUrls: "https://scope.klaytn.com/",
    nativeCurrency: {
      name: "KLAY",
      symbol: "KLAY",
      decimals: 18,
    },
  },
};

export const useMetamask = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountBalance, setAccountBalance] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [commonInfo, setCommonInfo] = useState(null);
  const [initConnect, setInitConnect] = useState(false);
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  const options = {
    autoClose: 5000,
    hideProgressBar: false,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("connect", connectWallet);
      window.ethereum.on("disconnect", disconnectWallet);
      window.ethereum.on("accountsChanged", () => window.location.reload());
      window.ethereum.on("chainChanged", () => window.location.reload());
    }
    axios.get("/api/mintings").then((response) => {
      if (response.data.code === HttpStatusCode.Ok) {
        setCommonInfo(response.data.data.commonInfo);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initConnect && commonInfo) {
      connectWallet();
      setInitConnect(false);
    }
  }, [initConnect, commonInfo]);

  const checkIsCorrectChain = (chainId) => {
    if (!commonInfo) return false;
    return chainId === commonInfo.chain;
  };

  const addNetwork = async (currentChainId, toChangeChainId) => {
    if (!checkIsCorrectChain(currentChainId)) {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: Web3.utils.numberToHex(toChangeChainId),
            rpcUrls: [networkInfo[toChangeChainId].rpcUrl],
            chainName: networkInfo[toChangeChainId].name,
            nativeCurrency: networkInfo[toChangeChainId].nativeCurrency,
            blockExplorerUrls: [networkInfo[toChangeChainId].blockExplorerUrls],
          },
        ],
      });
    }
  };

  const changeNetwork = async (currentChainId, toChangeChainId) => {
    if (!checkIsCorrectChain(currentChainId)) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: Web3.utils.numberToHex(toChangeChainId),
            },
          ],
        });
        console.log("You have succefully switched network");
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          console.log("This network is not available in your metamask, please add it");
          throw new Error("클레이튼 네트워크 사용 불가");
        }
        console.log("Failed to switch to the network");
        throw new Error("잘못된 네트워크");
      }
    }
  };

  const connectWallet = async () => {
    try {
      if (isMobile) {
        if (!window.ethereum || !window.ethereum.isMetaMask) {
          const address = window.location.href.split("://")[1];
          window.location.href = `https://metamask.app.link/dapp/${address}`;
          return;
        }
      } else if (typeof window.ethereum === "undefined") {
        window.location.href = "https://metamask.io/download.html";
        return;
      }
      if (!commonInfo) {
        setInitConnect(true);
        return;
      }
      setLoading(true);
      // Get the hexChainId
      const hexChainId = await window.ethereum.request({
        method: "eth_chainId",
      });

      // Convert that into the chainId as number
      const chainId = Web3.utils.hexToNumber(hexChainId);

      await addNetwork(chainId, commonInfo.chain);

      await changeNetwork(chainId, commonInfo.chain);

      const walletAddress = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });

      // 메타마스크 서명
      // try {
      //   const from = walletAddress[0];
      //   const siweMessage = `AI + DYOR = Ultimate Answer to Unlock Web3 Universe`;
      //   const encoder = new TextEncoder();
      //   const utf8Bytes = encoder.encode(siweMessage);
      //   const utf8HexString = Array.from(utf8Bytes)
      //     .map((byte) => byte.toString(16).padStart(2, "0"))
      //     .join("");

      //   const msg = `0x${utf8HexString}`;
      //   const sign = await window.ethereum.request({
      //     method: "personal_sign",
      //     params: [msg, from],
      //   });
      //   console.log(sign);
      // } catch (err) {
      //   console.error(err);
      // }

      accountChangedHandler(walletAddress[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.code === -32002) {
        toast.warn("지갑 연결이 이미 진행중입니다. 메타마스크를 확인 해 주세요.", options);
      } else if (error.code !== 4001) {
        setError(error.message);
      }
      setLoading(false);
    }
  };

  const disconnectWallet = () => {
    setSelectedAccount(null);
    setAccountBalance(null);
  };

  const accountChangedHandler = (newAccount) => {
    setSelectedAccount(newAccount);
    setBalance(newAccount);
  };

  const setBalance = (address) => {
    window.ethereum.request({ method: "eth_getBalance", params: [address, "latest"] }).then((response) => {
      setAccountBalance(convertFromHexDecimal(response));
    });
  };

  return {
    connectWallet,
    disconnectWallet,
    selectedAccount,
    accountBalance,
    error,
    loading,
  };
};
