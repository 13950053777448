// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
  background: rgba(0, 0, 0, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/components/info/Popup.scss"],"names":[],"mappings":"AAEA;EACI,8BAAA;AADJ","sourcesContent":["@import \"../../styles/mixins.scss\";\r\n\r\n.popup-overlay {\r\n    background: rgba(0, 0, 0, 0.7);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
