import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const location = useLocation();

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const onHome = () => {
        navigate('/#home')
        onClose()
    }

    const onIntro = () => {
        navigate('/#intro')
        onClose()
    }

    const onTheme = () => {
        navigate('/#theme')
        onClose()
    }

    const onNFTs = () => {
        navigate('/#nft')
        onClose()
    }

    const onRoadmap = () => {
        navigate('/#roadmap')
        onClose()
    }

    const onNotice = () => {
        navigate('/#notice')
        onClose()
    }

    const onTeam = () => {
        navigate('/#team')
        onClose()
    }

    return (
        <header className='fixed left-0 right-0 bg-[#333333] z-[100]'>
            <div className='flex flex-row items-center justify-center h-[60px] md:h-[71px] px-[24px] border-b-[2px] border-b-[#B1B1B1]'>
                <div className='flex flex-row items-center cursor-pointer' onClick={() => { navigate('/#home') }}>
                    <img src='/img/ic_logo.png' className='w-[27px] md:w-[38px]' />
                    <p className='text-[16px] md:text-[20px] text-[white] ml-[9px]'>규장각 디지털 헤리티지</p>
                </div>
                <div className='flex md:hidden flex-1 justify-end' onClick={showDrawer}>
                    <img src='/img/ic_menu.png' className='w-[27px] md:w-[38px]' />
                </div>
                <div className='hidden md:flex flex-row ml-[26px] lg:ml-[126px] items-center'>
                    <div id='menu-buttons' className={(location.pathname == '/' || location.pathname.includes('notice')) ? 'flex flex-row items-center gap-x-[30px]' : 'flex flex-row items-center gap-x-[30px] h-0 overflow-hidden'}>
                        <p onClick={onHome} className='cursor-pointer text-[16px] text-white' id='menu-btn-home'>
                            HOME
                        </p>
                        <p onClick={onIntro} className='cursor-pointer text-[16px] text-[#A0A0A0]' id='menu-btn-intro'>
                            소개
                        </p>
                        <p onClick={onTheme} className='cursor-pointer text-[16px] text-[#A0A0A0]' id='menu-btn-theme'>
                            테마
                        </p>
                        <p onClick={onNFTs} className='cursor-pointer text-[16px] text-[#A0A0A0]' id='menu-btn-nft'>
                            NFTs
                        </p>
                        <p onClick={onRoadmap} className='cursor-pointer text-[16px] text-[#A0A0A0]' id='menu-btn-roadmap'>
                            로드맵
                        </p>
                        <p onClick={onNotice} className='cursor-pointer text-[16px] text-[#A0A0A0]' id='menu-btn-notice'>
                            공지
                        </p>
                        <p onClick={onTeam} className='cursor-pointer text-[16px] text-[#A0A0A0]' id='menu-btn-team'>
                            팀
                        </p>
                    </div>
                    <div className='flex flex-row items-center ml-[26px] lg:ml-[126px] gap-x-[20px] menuBtn'>
                        {(location.pathname != '/' && !location.pathname.includes('notice')) &&
                            <Button onClick={() => { navigate('/') }} className='h-[35px] rounded-[20px] font-bold text-[15px] px-[27px]'>
                                Home
                            </Button>
                        }
                        {location.pathname != '/minting' &&
                            <Button onClick={() => { navigate('/minting') }} className='h-[35px] rounded-[20px] font-bold text-[15px] px-[27px]'>
                                NFT Mint
                            </Button>
                        }
                        {(location.pathname != '/holder' && location.pathname != '/admin') &&
                            <Button onClick={() => { navigate('/holder') }} className='h-[35px] rounded-[20px] font-bold text-[15px] px-[27px]'>
                                Holder
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <Drawer placement="right" onClose={onClose} open={open}
                width={195}
                styles={
                    {
                        header: { display: "none" },
                        body: { padding: 15 },
                        content: { background: "#6F6F6F", height: 543, borderBottomLeftRadius: 27 }
                    }}>
                <div className='flex flex-row items-center' onClick={() => { navigate('/#home') }}>
                    <img src='/img/ic_logo.png' className='w-[27px] h-[23px]' />
                    <p className='text-[12px] text-[white] font-medium ml-[9px]'>규장각 디지털 헤리티지</p>
                </div>
                <div className='h-[1px] bg-[#C1C1C1] mt-[14px]' />
                <div className='px-[12px] mt-[17px]'>
                    <div id='menu-buttons-mo' className='flex flex-col items-start gap-y-[30px]'>
                        <p onClick={onHome} className='cursor-pointer leading-[16px] text-[16px] text-white' id='menu-btn-home-mo'>
                            HOME
                        </p>
                        <p onClick={onIntro} className='cursor-pointer leading-[16px] text-[16px] text-[#C1C1C1]' id='menu-btn-intro-mo'>
                            소개
                        </p>
                        <p onClick={onTheme} className='cursor-pointer leading-[16px] text-[16px] text-[#C1C1C1]' id='menu-btn-theme-mo'>
                            테마
                        </p>
                        <p onClick={onNFTs} className='cursor-pointer leading-[16px] text-[16px] text-[#C1C1C1]' id='menu-btn-nft-mo'>
                            NFTs
                        </p>
                        <p onClick={onRoadmap} className='cursor-pointer leading-[16px] text-[16px] text-[#C1C1C1]' id='menu-btn-roadmap-mo'>
                            로드맵
                        </p>
                        <p onClick={onNotice} className='cursor-pointer leading-[16px] text-[16px] text-[#C1C1C1]' id='menu-btn-notice-mo'>
                            공지
                        </p>
                        <p onClick={onTeam} className='cursor-pointer leading-[16px] text-[16px] text-[#C1C1C1]' id='menu-btn-team-mo'>
                            팀
                        </p>
                    </div>
                </div>

                <div className='flex flex-col items-start ml-[4px] mt-[73px] menuBtn gap-y-[20px]'>
                    {(location.pathname != '/' && !location.pathname.includes('notice')) &&
                        <Button onClick={() => { navigate('/'); onClose(); }} className='w-[121px] h-[35px] rounded-[20px] font-bold text-[15px] px-[27px]'>
                            Home
                        </Button>
                    }
                    {location.pathname != '/minting' &&
                        <Button onClick={() => { navigate('/minting'); onClose(); }} className='w-[121px] h-[35px] rounded-[20px] font-bold text-[15px] px-[27px]'>
                            NFT Mint
                        </Button>
                    }
                    {(location.pathname != '/holder' && location.pathname != '/admin') &&
                        <Button onClick={() => { navigate('/holder'); onClose(); }} className='w-[121px] h-[35px] rounded-[20px] font-bold text-[15px] px-[27px]'>
                            Holder
                        </Button>
                    }
                </div>
            </Drawer>
        </header>
    );
};
export default Header;