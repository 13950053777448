import React, { useState } from "react";

import cn from "classnames";
import { toast } from "react-toastify";
import { mint } from "../../hooks/useMint";
import { converToWei } from "../../utility/helpers";
import styles from "./MintingInfo.module.scss";
import Popup from "reactjs-popup";
import "./Popup.scss";
import { Button, Modal } from "antd";
import useScreenSize from "../../hooks/useScreenSize";
import moment from "moment";
import axios, { HttpStatusCode } from "axios";

export const MintingInfo = ({ nftInfo, refreshInfo }) => {
  const [mintingCount, setMintingCount] = useState(1);
  const [success, setSuccess] = useState(true);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("No Error");
  const openModal = (msg) => {
    setErrorMsg(msg);
    setOpen(true);
  };
  const closeModal = () => setOpen(false);

  const [showMintingModal, setShowMintingModal] = useState(false);
  const cancelMintingModal = (e) => {
    setShowMintingModal(false);
  };

  function plusCount() {
    if (isNFTInfoEmpty()) return;
    if (mintingCount < nftInfo.maxMintingCount) {
      setMintingCount(mintingCount + 1);
    }
  }

  function minusCount() {
    if (mintingCount > 1) {
      setMintingCount(mintingCount - 1);
    }
  }

  const onMintingSuccess = async (success) => {
    setSuccess(success);
    if (!success) return;

    refreshInfo();
    setMintingCount(1);

    const res = await axios.put(`/api/mintings`, {
      address: window.ethereum.selectedAddress,
      nft_id: nftInfo.id,
      count: mintingCount,
    });

    console.log(res.data.code);
  };

  async function minting() {
    const options = {
      autoClose: 5000,
      hideProgressBar: false,
      position: toast.POSITION.TOP_CENTER,
      pauseOnHover: true,
    };

    if (!window.ethereum || !window.ethereum.isMetaMask) {
      toast.error("No crypto wallet found", options);
      return;
    }

    if (isNFTInfoEmpty()) {
      toast.warn("Connecting to the server", options);
      return;
    }

    if (moment(nftInfo.startTime) > moment()) {
      toast.warn("Not started", options);
      return;
    }

    if (nftInfo.remaining <= 0) {
      toast.warn("Sold out", options);
      return;
    }

    mint(
      nftInfo.minterAddress,
      nftInfo.id,
      converToWei(nftInfo.mintPrice.toString()),
      mintingCount,
      onMintingSuccess,
      openModal
    );
  }

  const isSoldout = () => {
    return nftInfo ? nftInfo.remaining <= 0 : false;
  };

  const isNotStarted = () => {
    return nftInfo ? moment(nftInfo.startTime) > moment() : false;
  };

  const isNFTInfoEmpty = () => {
    return !nftInfo || Object.keys(nftInfo).length === 0;
  };

  return (
    <div className={styles.minting_info_container}>
      <div className={styles.info_block}>
        <div className={styles.info_title}>민팅 대상</div>
        <div className={styles.info_contents}>
          {isNFTInfoEmpty() ? "Loading" : nftInfo.target}
        </div>
      </div>
      <div className={styles.info_block}>
        <div className={styles.info_title}>민팅 시작 시간</div>
        <div className={styles.info_contents}>
          {isNFTInfoEmpty()
            ? "Loading"
            : moment(nftInfo.startTime).format("YYYY-MM-DD HH:mm:ss")}
        </div>
      </div>
      <div className={styles.info_block}>
        <div className={styles.info_title}>민팅 금액</div>
        <div className={styles.info_contents}>
          {isNFTInfoEmpty() ? "Loading" : nftInfo.mintPrice} KLAY
        </div>
      </div>
      <div className={styles.info_block}>
        <div className={styles.info_title}>총 수량</div>
        <div className={styles.info_contents}>
          {isNFTInfoEmpty() ? "Loading" : nftInfo.total}
        </div>
      </div>
      <div className={styles.info_block}>
        <div className={styles.info_title}>최대 민팅 갯수</div>
        <div className={styles.info_contents}>
          최대 {isNFTInfoEmpty() ? 0 : nftInfo.maxMintingCount} NFTs
        </div>
      </div>
      <div className={styles.info_block}>
        <div className={styles.info_title}>지갑당 구매 갯수 제한</div>
        <div className={styles.info_contents}>
          {isNFTInfoEmpty() ? 0 : nftInfo.mintCountPerWallet}
        </div>
      </div>
      <div className={styles.progress_block}>
        <div className={styles.progress_num_block}>
          <div className={styles.progress_num_text}>
            {isNFTInfoEmpty() ? 0 : nftInfo.total}
          </div>
          <div className={styles.progress_num_text}>
            {isNFTInfoEmpty() ? 0 : nftInfo.total / 2}
          </div>
          <div className={styles.progress_num_text}>0</div>
        </div>
        <progress
          className={styles.progress_bar}
          value={isNFTInfoEmpty() ? 0 : nftInfo.total - nftInfo.remaining}
          max={isNFTInfoEmpty() ? 0 : nftInfo.total}
        ></progress>
      </div>
      <div className={styles.control_container}>
        <div className={styles.amount_block}>갯수</div>
        <div className={styles.control_block}>
          <button className={styles.minus_btn} onClick={minusCount}></button>
          <div className={styles.count_block}>{mintingCount}</div>
          <button className={styles.plus_btn} onClick={plusCount}></button>
        </div>
        {/* <button
          className={cn(styles.button_block, {
            [styles.button_disabled]: isSoldout() || isNotStarted(),
          })}
          onClick={() => {
            setShowMintingModal(true);
          }}
        >
          {isSoldout() ? "판매 완료" : isNotStarted() ? "판매 시작 전" : "민팅"}
        </button> */}
        <button
          className={cn(styles.button_block)}
          onClick={() => {
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSeyG9KylSvxok2umVnIplOV_E7ycS6nj2Jcgxfso5753MHJuw/viewform?usp=sf_link",
              "_blank"
            );
          }}
        >
          {"계좌이체 민팅"}
        </button>

        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <div className={styles.popup_block}>
            {success ? (
              <div className={styles.popup_contents_block}>
                <div className={styles.popup_icon_success}></div>
                <div className={styles.popup_title}>Congratulation</div>
                <div className={styles.popup_contents}>Minting Success!</div>
              </div>
            ) : (
              <div className={styles.popup_contents_block}>
                <div className={styles.popup_icon_failed}></div>
                <div className={styles.popup_title}>So sad...</div>
                <div className={styles.popup_contents}>{errorMsg}</div>
              </div>
            )}
            <div className={styles.close_popup} onClick={closeModal}>
              Close
            </div>
          </div>
        </Popup>

        <Modal
          title=""
          open={showMintingModal}
          onCancel={cancelMintingModal}
          footer={null}
          closeIcon={null}
          styles={{
            content: {
              padding: 0,
              borderRadius: 20,
              overflow: "hidden",
              background: "transparent",
            },
          }}
          width={915}
        >
          <div className="flex flex-col md:flex-row md:h-[500px] bg-[transparent] items-center justify-center">
            <div className="flex flex-col w-[290px] md:w-[517px] pt-[38px] md:pt-[31px] pb-[61px] md:pb-[50px] px-[33px] md:px-[59px] relative bg-white rounded-[20px]">
              <p className="text-[24px] leading-[38px] md:text-[36px] font-[700] text-black">
                민팅 방식 선택
              </p>
              <p className="mt-[44px] md:mt-[41px] text-[15px] leading-[23px] font-[700] text-black whitespace-pre-line self-center">
                민팅 방식을 선택해 주세요.
                <br />
                메타마스크로 민팅 시 {useScreenSize().width < 700 ? "\n" : ""}
                메타마스크를 연결해 주세요.
              </p>
              <Button
                className="bg-[#D4D4D4] w-[190px] md:w-[339px] h-[67px] md:h-[54px] rounded-[10px] text-white text-[20px] font-[700] mt-[59px] md:mt-[42px] self-center"
                onClick={() => {
                  minting();
                  cancelMintingModal();
                }}
              >
                메타마스크로 민팅
              </Button>
              <Button
                className="bg-[#525252] w-[190px] md:w-[339px] h-[67px] md:h-[54px] rounded-[10px] text-white text-[20px] font-[700] mt-[24px] md:mt-[20px] self-center"
                onClick={() => {
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSeyG9KylSvxok2umVnIplOV_E7ycS6nj2Jcgxfso5753MHJuw/viewform?usp=sf_link",
                    "_blank"
                  );
                }}
              >
                계좌이체 민팅
              </Button>
              <img
                src="/img/ic_close_black.png"
                className="absolute cursor-pointer w-[33px] h-[33px] top-[40px] right-[27px] md:top-[27px]"
                alt="close"
                onClick={cancelMintingModal}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
