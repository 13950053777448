// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoticeModal_modal__QDHbV {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation: NoticeModal_showModal__rFGUX 0.2s forwards;
  overflow: auto;
  z-index: 999;
}
@keyframes NoticeModal_showModal__rFGUX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.NoticeModal_modal__QDHbV .NoticeModal_outer__1PXK0 {
  position: relative;
  width: 100%;
  max-width: 304px;
  margin: auto;
  background: #fff;
  border-radius: 16px;
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/components/notice/NoticeModal.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,UAAA;EACA,qDAAA;EACA,cAAA;EACA,YAAA;AADJ;AAOI;EACI;IACI,UAAA;EALV;EAQM;IACI,UAAA;EANV;AACF;AASI;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,UAAA;AAPR","sourcesContent":["@import '../../styles/mixins.scss';\n\n.modal {\n    display: flex;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    padding: 48px;\n    background: rgba(#000,.5);\n    opacity: 0;\n    animation: showModal .2s forwards;\n    overflow: auto;\n    z-index: 999;\n\n    @include breakpoint(m) {\n        padding: 32px 16px;\n    }\n\n    @keyframes showModal {\n        0% {\n            opacity: 0;\n        }\n\n        100% {\n            opacity: 1;\n        }\n    }\n\n    .outer {\n        position: relative;\n        width: 100%;\n        max-width: 304px;\n        margin: auto;\n        background: #fff;\n        border-radius: 16px;\n        z-index: 2;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `NoticeModal_modal__QDHbV`,
	"showModal": `NoticeModal_showModal__rFGUX`,
	"outer": `NoticeModal_outer__1PXK0`
};
export default ___CSS_LOADER_EXPORT___;
