
const NFT2 = ({ item, onClick }) => {
  return (
    <div onClick={onClick} className="w-full max-w-[210px] rounded-[16px] bg-white pt-[12px] py-[17px] flex flex-col px-[14px] border-[#F2F2F2] border-[2px] shadow-md cursor-pointer">
      <img src={item.imageUrl} className="w-full h-[181px] rounded-[8px] aspect-1 object-cover" />
      <div className="px-[9px] mt-[5px]">
        <p className="text-[12px] leading-[24px] text-[#525252] whitespace-pre-line line-clamp-1 font-bold">
          {item.categoty}
        </p>
        <p className="text-[10px] leading-[16px] text-[#525252] whitespace-pre-line line-clamp-1 font-medium">
          {item.tokenName}
        </p>
        <p className="text-[16px] leading-[24px] text-[#525252] whitespace-pre-line line-clamp-1 font-bold">
          {item.title}
        </p>
      </div>
    </div>
  )
};

export default NFT2;