// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoticePopup_title__b6XEB {
  margin-top: 44px;
  margin-left: 37px;
  margin-bottom: 54px;
  color: #253261;
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
}

.NoticePopup_contents__hMuOa {
  padding: 0 30px;
  display: block;
}
.NoticePopup_contents__hMuOa.NoticePopup_full__speWo {
  padding: 0 0;
}
.NoticePopup_contents__hMuOa.NoticePopup_full__speWo img {
  border-radius: 16px 16px 0 0;
}
.NoticePopup_contents__hMuOa.NoticePopup_disabled__-LAtS {
  pointer-events: none;
  cursor: none;
}

.NoticePopup_image__rmEI2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.NoticePopup_footer__YnpLC {
  display: flex;
  margin: 9px 0 9px 17px;
}

.NoticePopup_close_button__aioHv {
  padding: 9px 0;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: #253261;
  border-radius: 0 0 16px 16px;
  color: #FFF;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
}
.NoticePopup_close_button__aioHv:hover {
  background: #152251;
}`, "",{"version":3,"sources":["webpack://./src/components/notice/NoticePopup.module.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,iBAAA;EACA,mBAAA;EAEA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;;AAKA;EACI,eAAA;EACA,cAAA;AAFJ;AAII;EAKI,YAAA;AANR;AAEQ;EACI,4BAAA;AAAZ;AAMI;EACI,oBAAA;EACA,YAAA;AAJR;;AAQA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AALJ;;AAQA;EACI,aAAA;EACA,sBAAA;AALJ;;AAQA;EACI,cAAA;EACA,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,mBAAA;EACA,4BAAA;EAEA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AANJ;AAQI;EACI,mBAAA;AANR","sourcesContent":["@import \"../../styles/mixins.scss\";\n\n.title {\n    margin-top: 44px;\n    margin-left: 37px;\n    margin-bottom: 54px;\n\n    color: #253261;\n    font-size: 32px;\n    font-weight: 700;\n    line-height: 24px;\n}\n\n.contents {\n    padding: 0 30px;\n    display: block;\n\n    &.full {\n        img {\n            border-radius: 16px 16px 0 0;\n        }\n\n        padding: 0 0;\n    }\n\n    &.disabled {\n        pointer-events: none;\n        cursor: none;\n    }\n}\n\n.image {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n\n.footer {\n    display: flex;\n    margin: 9px 0 9px 17px;\n}\n\n.close_button {\n    padding: 9px 0;\n    width: 100%;\n    height: fit-content;\n    background: #253261;\n    border-radius: 0 0 16px 16px;\n\n    color: #FFF;\n    text-align: center;\n    font-size: 10px;\n    font-weight: 600;\n    line-height: 24px;\n\n    &:hover {\n        background: #152251;\n    }\n\n    @include breakpoint(m) {\n        font-size: 17px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `NoticePopup_title__b6XEB`,
	"contents": `NoticePopup_contents__hMuOa`,
	"full": `NoticePopup_full__speWo`,
	"disabled": `NoticePopup_disabled__-LAtS`,
	"image": `NoticePopup_image__rmEI2`,
	"footer": `NoticePopup_footer__YnpLC`,
	"close_button": `NoticePopup_close_button__aioHv`
};
export default ___CSS_LOADER_EXPORT___;
