import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./NoticePopup.module.scss";
import NoticeModal from "./NoticeModal";
import Checkbox from "../checkbox/Checkbox";

export const NoticePopup = () => {
  const [showNoticeModal, setShowNoticeModal] = useState(false);
  const [noticeItemList] = useState([
    // {
    //   key: 101,
    //   title: "",
    //   image: require("../../assets/images/notices/notice2.jpg"),
    // },
  ]);
  const [noticeItemKey, setNoticeItemKey] = useState(null);
  const [doNotShowAgainChecked, setDoNotShowAgainChecked] = useState(false);
  const [checkedNoticeKeyList, setCheckedNoticeKeyList] = useState([]);

  const showNextNotice = () => {
    const currentTime = new Date().getTime();

    const nextNoticeItem = noticeItemList.find((x) => {
      if (checkedNoticeKeyList.includes(x.key)) {
        return false;
      }
      const savedTime = localStorage.getItem("notice_" + x.key);
      return !savedTime || currentTime >= savedTime;
    });

    if (nextNoticeItem) {
      setNoticeItemKey(nextNoticeItem.key);
    }
  };

  useEffect(() => {
    if (noticeItemKey !== null) {
      setShowNoticeModal(true);
    }
  }, [noticeItemKey]);

  useEffect(() => {
    showNextNotice();
  }, [checkedNoticeKeyList]);

  const closeAndNextPopupShoing = () => {
    setShowNoticeModal(false);
    setCheckedNoticeKeyList([...checkedNoticeKeyList, noticeItemKey]);
    if (doNotShowAgainChecked) {
      saveDoNotShowAgainNotice();
    }
    setDoNotShowAgainChecked(false);
  };

  const saveDoNotShowAgainNotice = () => {
    const after24Hours = new Date().getTime() + 24 * 60 * 60 * 1000;
    localStorage.setItem("notice_" + noticeItemKey, after24Hours);
  };

  const getNoticeItem = (key) => {
    const item = noticeItemList.find((x) => x.key == key);
    if (item) {
      return item;
    }
    return {
      title: "",
      image: null,
    };
  };

  useEffect(() => {
    showNextNotice();
  }, []);

  return (
    <NoticeModal visible={showNoticeModal} onClose={() => closeAndNextPopupShoing()}>
      {getNoticeItem(noticeItemKey).title && <div className={styles.title}>{getNoticeItem(noticeItemKey).title}</div>}
      <a href={getNoticeItem(noticeItemKey).link} target="_blank" rel="noreferrer" key={noticeItemKey} className={cn(styles.contents, { [styles.full]: !getNoticeItem(noticeItemKey).title }, { [styles.disabled]: !getNoticeItem(noticeItemKey).link })}>
        <img className={styles.image} alt="notice" src={getNoticeItem(noticeItemKey).image} />
      </a>
      <div className={styles.footer}>
        <Checkbox className={styles.checkbox} content="24시간 동안 보지 않기" value={doNotShowAgainChecked} onChange={() => setDoNotShowAgainChecked(!doNotShowAgainChecked)} />
      </div>
      <button className={cn("button", styles.close_button)} onClick={() => closeAndNextPopupShoing()}>
        닫기
      </button>
    </NoticeModal>
  );
};
