import { useEffect } from 'react';
import './App.scss';
import PageRoutes from './components/PageRoutes';
import './styles/global.scss'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <PageRoutes />
    </div>
  );
}

export default App;
