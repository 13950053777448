import { Button } from 'antd';
import React from 'react';

const BtnCategory2 = (props) => {
    return (
        <Button onClick={props.onClick} className={props.selected ? 'h-[28px] px-[10px] py-0 text-black text-[12px] font-[400] rounded-[14px] bg-[#FFEBA6]' : 'h-[28px] px-[12px] py-0 text-black text-[12px] font-[400] rounded-[14px] bg-[#CDCDCD]'}>
            {props.title}
        </Button >
    );
};
export default BtnCategory2;