import { Button, Table } from "antd";
import axios, { HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NoticeDetail = () => {
  const navigate = useNavigate();

  const [noticeTitle, setNoticeTitle] = useState("");
  const [noticeContents, setNoticeContents] = useState("");
  const [noticeCreateAt, setNoticeCreatedAt] = useState("");

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const Id = params.get("Id");
    if (Id) {
      requestGetNotificationDetail(Id);
    }

    window.scrollTo(0, 0);
  }, []);

  const requestGetNotificationDetail = (p_id) => {
    axios.get(`/api/landings/notice/${p_id}`).then((response) => {
      if (response.data.code == HttpStatusCode.Ok) {
        setNoticeTitle(response.data.data.Title);
        setNoticeContents(response.data.data.Contents);
        setNoticeCreatedAt(response.data.data.CreateAt);
      }
    });
  };

  return (
    <div className="overflow-hidden bg-white flex flex-col">
      <section className="flex flex-col items-center px-[24px] md:px-0 py-[63px] md:py-[115px] md:pb-[170px] w-full md:w-[910px] self-center">
        <p className="text-[36px] md:text-[48px] leading-[56px] md:leading-[71px] font-[700] text-black tracking-tight self-start">
          공지사항
        </p>
        <div className="w-full flex flex-col mt-[32px] borer-[#B0B0B0] border-[1px] rounded-[19px] px-[33px] pt-[31px] pb-[120px]">
          <div className="w-full flex flex-row items-end justify-between gap-x-[10px] border-b-[1px] border-[#E9E9E9] pb-[13px]">
            <p className="text-[20px] md:text-[24px] font-[700] leading-[24px] text-black">
              {noticeTitle}
            </p>
            <p className="text-[14px] font-[400] leading-[14px] md:leading-[24px] text-[#B0B0B0]">
              {noticeCreateAt.substr(0, 10)}
            </p>
          </div>

          <p className="mt-[30px] text-[14px] font-[400] leading-[16px] text-black whitespace-pre-line">
            {noticeContents}
          </p>
        </div>
        <Button
          className="self-end bg-[#525252] text-white rounded-[20px] w-[82px] h-[35px] mt-[15px]"
          onClick={() => {
            navigate("/notice");
          }}
        >
          목록
        </Button>
      </section>
    </div>
  );
};
export default NoticeDetail;
